const { urlTechTags } = require('swissdev-tech-tags')

const LOCAL_SITE_NAME = "GDJ"

const usCities = {
    "all": ["Atlanta", "Chicago", "Dallas", "Houston", "Los-Angeles", "Miami", "New-York-City", "Philadelphia", "Albuquerque", "Austin", "Baltimore",
        "Boston", "Charlotte", "Cleveland", "Columbus", "Denver", "Detroit", "El-Paso", "Fort-Worth", "Indianapolis", "Jacksonville", "Kansas-City",
        "Las-Vegas", "Louisville", "Milwaukee", "Minneapolis", "Nashville", "Oklahoma-City", "Orlando", "Phoenix", "Pittsburgh", "Portland", "Sacramento",
        "Saint-Louis", "San-Antonio", "San-Diego", "San-Francisco", "San-Jose", "Seattle", "Tampa", "Tucson", "Washington", "Memphis", "Salt-Lake-City"],
    "California": ["Los-Angeles", "San-Francisco", "San-Diego", "San-Jose", "Sacramento", "Palo-Alto", "Mountain-View", "Fresno", "Anaheim", "Riverside", "Redding",
        "Chico", "Santa-Rosa", "Salinas", "Monterey", "Visalia", "Santa-Maria", "Santa-Barbara", "San-Luis-Obispo", "Bakersfield", "Long-Beach",
        "Simi-Valley", "Oakland", "Stockton", "Irvine", "Chula-Vista", "Fremont", "Modesto", "Fontana", "San-Bernardino", "Moreno-Valley", "Oxnard", "Santa-Clarita",
        "Glendale", "Huntington-Beach", "Rancho-Cucamonga", "Elk-Grove", "Oceanside", "Corona", "Garden-Grove", "Hayward", "Lancaster", "Palmdale"],
    "Texas": ["Houston", "Dallas", "San-Antonio", "Fort-Worth", "El-Paso", "Austin", "Amarillo", "Lubbock", "Corpus-Christi", "Laredo", "Odessa",
        "Midland", "Abilene", "Beaumont", "Galveston", "McAllen", "Brownsville", "Arlington", "Plano", "Garland", "Irving", "Frisco", "McKinney", "Grand-Prairie",
        "Killeen", "Pasadena", "Waco", "Denton", "Wichita-Falls", "College-Station", "Temple", "Tyler", "San-Marcos", "Victoria", "San-Angelo"],
    "Florida": ["Miami", "Orlando", "Tampa", "Jacksonville", "Tallahassee", "Pensacola", "Panama-City", "Gainesville", "St-Augustine", "Ocala", "Daytona-Beach",
        "Clearwater", "St-Petersburg", "Lakeland", "Kissimmee", "Melbourne", "Port-St-Lucie", "Fort-Myers", "West-Palm-Beach", "Fort-Lauderdale", "Hollywood",
        "Hialeah", "Cape-Coral", "Pembroke-Pines", "Miramar"],
    "New-York": ["New-York-City", "Buffalo", "Rochester", "Syracuse", "Albany", "Long-Island", "Watertown", "Ithaca", "Saratoga-Springs", "Yonkers", "Utica",
        "Plattsburgh", "Auburn", "Binghamton", "Schenectady", "Poughkeepsie", "Newburgh"],
    "New-Jersey": ["Newark", "Jersey-City", "Paterson", "Edison", "Trenton", "Toms-River", "New-Brunswick", "Hackensack", "Elizabeth", "Clifton", "Union-City",
        "Lakewood-Township", "Woodbridge-Township", "Hamilton-Township", "North-Bergen", "Hoboken", "Atlantic-City", "Cherry-Hill"],
    "Hawaii": ["Honolulu", "Hilo", "Kahului"],
    "Alaska": ["Anchorage", "Fort-Yukon", "Juneau", "Fairbanks"],
    "Washington": ["Seattle", "Tacoma", "Spokane", "Olympia", "Vancouver", "Bellevue", "Bellingham", "Everett", "Kennewick", "Richland"],
    "Oregon": ["Portland", "Salem", "Eugene", "Bend", "Medford", "Albany", "Corvallis", "Hillsboro", "Beaverton"],
    "Idaho": ["Boise", "Idaho-Falls", "Pocatello", "Twin-Falls", "Nampa"],
    "Nevada": ["Las-Vegas", "Reno", "Carson-City", "Henderson", "Winnemucca"],
    "Montana": ["Helena", "Billings", "Bozeman", "Great-Falls", "Butte", "Missoula"],
    "Wyoming": ["Casper", "Cheyenne", "Rock-Springs"],
    "Utah": ["Salt-Lake-City", "Ogden", "Provo", "Logan", "St-George"],
    "Arizona": ["Phoenix", "Tucson", "Mesa", "Chandler", "Scottsdale", "Gilbert", "Glendale", "Tempe", "Peoria", "Surprise", "Yuma", "Flagstaff"],
    "Colorado": ["Denver", "Colorado-Springs", "Aurora", "Boulder", "Fort-Collins", "Pueblo", "Grand-Junction", "Lakewood", "Thornton", "Loveland"],
    "New-Mexico": ["Albuquerque", "Santa-Fe", "Roswell", "Las-Cruces"],
    "North-Dakota": ["Bismarck", "Fargo", "Grand-Forks"],
    "South-Dakota": ["Sioux-Falls", "Rapid-City"],
    "Nebraska": ["Omaha", "Lincoln"],
    "Kansas": ["Kansas-City", "Wichita", "Topeka", "Overland-Park", "Olathe", "Lawrence"],
    "Oklahoma": ["Oklahoma-City", "Tulsa", "Broken-Arrow", "Stillwater"],
    "Minnesota": ["Minneapolis", "St-Paul", "Duluth", "Rochester", "St-Cloud"],
    "Iowa": ["Des-Moines", "Cedar-Rapids", "Iowa-City", "Sioux-City", "Waterloo"],
    "Missouri": ["Saint-Louis", "Kansas-City", "Jefferson-City", "Columbia", "Springfield"],
    "Mississippi": ["Jackson", "Gulfport", "Southaven", "Hattiesburg", "Biloxi", "Tupelo", "Meridian"],
    "Arkansas": ["Little-Rock", "Fayetteville", "Fort-Smith", "Pine-Bluff", "Hot-Springs", "Jonesboro"],
    "Louisiana": ["New-Orleans", "Baton-Rouge", "Shreveport", "Lafayette", "Alexandria", "Monroe", "Lake-Charles"],
    "Wisconsin": ["Milwaukee", "Madison", "Green-Bay", "Eau-Claire", "Kenosha"],
    "Illinois": ["Chicago", "Springfield", "Peoria", "Rockford", "Bloomington", "Decatur"],
    "Michigan": ["Detroit", "Grand-Rapids", "Lansing", "Ann-Arbor", "Kalamazoo", "Flint"],
    "Indiana": ["Indianapolis", "Fort-Wayne", "South-Bend", "Lafayette", "Terre-Haute"],
    "Ohio": ["Cincinnati", "Cleveland", "Columbus", "Dayton", "Akron", "Toledo", "Youngstown"],
    "Kentucky": ["Louisville", "Lexington", "Bowling-Green", "Owensboro", "Frankfort"],
    "Tennessee": ["Nashville", "Memphis", "Chattanooga", "Knoxville", "Clarksville", "Murfreesboro", "Jackson", "Franklin"],
    "Alabama": ["Montgomery", "Birmingham", "Huntsville", "Mobile", "Tuscaloosa", "Auburn", "Dothan"],
    "Georgia": ["Atlanta", "Augusta", "Columbus", "Macon", "Savannah", "Athens"],
    "South-Carolina": ["Columbia", "Charleston", "Greenville", "Florence", "Myrtle-Beach"],
    "North-Carolina": ["Charlotte", "Raleigh", "Greensboro", "Durham", "Winston-Salem", "Fayetteville", "Wilmington"],
    "Virginia": ["Richmond", "Virginia-Beach", "Norfolk", "Newport-News", "Chesapeake", "Arlington", "Roanoke"],
    "West-Virginia": ["Morgantown", "Charleston", "Huntington"],
    "District-Of-Columbia": ["Washington"],
    "Maryland": ["Baltimore", "Gaithersburg", "Frederick", "Hagerstown", "Annapolis"],
    "Delaware": ["Wilmington", "Dover"],
    "Pennsylvania": ["Philadelphia", "Pittsburgh", "Allentown", "Harrisburg", "Scranton", "Reading", "Erie"],
    "Connecticut": ["Bridgeport", "New-Haven", "Hartford", "Sartford", "Middletown", "Waterbury", "Danbury", "Stamford"],
    "Rhode-Island": ["Providence", "Cranston", "Warwick"],
    "Massachusetts": ["Boston", "Worcester", "Springfield", "Pittsfield", "Plymouth", "Lowell"],
    "Vermont": ["Burlington", "Montpelier", "Middlebury", "Rutland"],
    "New-Hampshire": ["Concord", "Manchester", "Nashua", "Portsmouth"],
    "Maine": ["Portland", "Augusta", "Waterville", "Bangor"]
}

const usStates = ["California", "Texas", "Florida", "New-York", 'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'Colorado',
    'Connecticut', 'Delaware', 'District-Of-Columbia', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
    'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
    'Nebraska', 'Nevada', 'New-Hampshire', 'New-Jersey', 'New-Mexico', 'North-Carolina', 'North-Dakota', 'Ohio',
    'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode-Island', 'South-Carolina', 'South-Dakota', 'Tennessee', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West-Virginia', 'Wisconsin', 'Wyoming']

const cityCategories = {
    SDJ: ["remote", "Zurich", "Aarau", "Baden", "Basel", "Bern", "Biel", "Chur", "Fribourg", "Geneva", "Olten", "Lausanne", "Liechtenstein", "Lugano", "Luzern", "Schaffhausen", "Sion", "Solothurn", "St-Gallen", "Thun", "Winterthur", "Zug"],
    GDJ: ["remote", "Berlin", "Cologne", "Dusseldorf", "Frankfurt", "Hamburg", "Munich", "Stuttgart", "Aachen", "Augsburg", "Bielefeld", "Braunschweig", "Bremen", "Chemnitz", "Darmstadt", "Dortmund", "Dresden", "Erfurt", "Essen", "Freiburg", "Hanover", "Heilbronn", "Ingolstadt", "Kaiserslautern", "Karlsruhe", "Kassel", "Kiel", "Konstanz", "Leipzig", "Magdeburg", "Mannheim", "Muenster", "Nuremberg", "Rostock", "Saarbrucken", "Ulm", "Wuerzburg"],
    NDJ: ["remote", "Amsterdam", "Apeldoorn", "Arnhem", "Breda", "Den-Haag", "Eindhoven", "Enschede", "Groningen", "Maastricht", "Nijmegen", "Rotterdam", "s-Hertogenbosch", "Utrecht", "Zwolle"],
    RDJ: ["remote", "Bucuresti", "Arad", "Bacau", "Baia-Mare", "Brasov", "Buzau", "Cluj-Napoca", "Constanta", "Craiova", "Galati", "Iasi", "Oradea", "Pitesti", "Ploiesti", "Sibiu", "Targu-Mures", "Timisoara"],
    UKJ: ["remote", "London", "Belfast", "Birmingham", "Bradford", "Brighton", "Bristol", "Cambridge", "Cardiff", "Coventry", "Edinburgh", "Exeter", "Glasgow", "Leeds", "Leicester", "Liverpool", "Manchester", "Milton-Keynes", "Newcastle", "Norwich", "Nottingham", "Oxford", "Plymouth", "Portsmouth", "Preston", "Reading", "Sheffield", "Southampton", "Sunderland"],
    USJ: usCities,
    HNM: usCities,
}

const stateCategories = {
    SDJ: [],
    GDJ: [],
    RDJ: [],
    UKJ: [],
    USJ: usStates,
    HNM: usStates
}

const pageRoot = {
    SDJ: "https://swissdevjobs.ch",
    GDJ: "https://germantechjobs.de",
    NDJ: "https://devitjobs.nl",
    RDJ: "https://devjob.ro",
    UKJ: "https://devitjobs.uk",
    USJ: "https://devitjobs.com",
    HNM: "https://hiringnearme.jobs",
}

const jobAppRoot = {
    SDJ: "https://jobapp.swissdevjobs.ch",
    GDJ: "https://jobapp.germantechjobs.de",
    NDJ: "https://jobapp.devitjobs.nl",
    RDJ: "https://jobapp.devjob.ro",
    UKJ: "https://jobapp.devitjobs.uk",
    USJ: "https://jobapp.devitjobs.com",
    HNM: "https://hiringnearme.jobs", // there is no jobApp for HNM
}

const fullName = {
    SDJ: "SwissDevJobs",
    GDJ: "GermanTechJobs",
    NDJ: "DevITjobs",
    RDJ: "DevJob.ro",
    UKJ: "DevITjobs.uk",
    USJ: "DevITjobs",
    HNM: "HiringNearMe",
}

const currency = {
    SDJ: "CHF",
    GDJ: "EUR",
    NDJ: "EUR",
    RDJ: "RON",
    UKJ: "GBP",
    USJ: "USD",
    HNM: "USD",
}

const countryCode = {
    SDJ: "CH",
    GDJ: "DE",
    NDJ: "NL",
    RDJ: "RO",
    UKJ: "UK",
    USJ: "US",
    HNM: "US",
}

const country = {
    SDJ: "Switzerland",
    GDJ: "Germany",
    NDJ: "Netherlands",
    RDJ: "Romania",
    UKJ: "United Kingdom",
    USJ: "United States",
    HNM: "U.S.A.",
}

const inCountry = {
    SDJ: "in Switzerland",
    GDJ: "in Germany",
    NDJ: "in the Netherlands",
    RDJ: "in Romania",
    UKJ: "in the United Kingdom",
    USJ: "in the United States",
}

const countrish = {
    SDJ: "Swiss",
    GDJ: "German",
    NDJ: "Dutch",
    RDJ: "Romanian",
    UKJ: "British",
    USJ: "US",
    HNM: "U.S.",
}

const tawkId = {
    SDJ: "5cf75440267b2e578530ee22/default",
    GDJ: "5cf75440267b2e578530ee22/default", //on purpose the same as SDJ
    NDJ: "60f09200649e0a0a5ccc6bde/1falrkl7p",
    RDJ: "5f82c1394704467e89f66d87/default",
    UKJ: "5cf75440267b2e578530ee22/default", //on purpose the same as SDJ
    USJ: "5cf75440267b2e578530ee22/default", //on purpose the same as SDJ
    HNM: "none"
}

const wonderpushPublicKey = {
    SDJ: "9fa552c3d98e635ba9ddd529d50b29ce9f1f4a408710c4e96364bf21033ae020",
    GDJ: "3b0feaed2528f8b201faa3dfb9539b761cccf1a6d0eabca7b7a66423223f678f",
    NDJ: "c1872e04e2701e5d054ecf1ffbaf3469284ea6876017bdb511f205e3e96c13b8",
    RDJ: "90e6f13ac5712be33dfdb2d29a097e5928996ac3ce5cabb72a7a0edeec4cb2b9",
    UKJ: "f8f8f9fd2ed64b4c8153ff214d67f473f2dfa5932200dd0491e17b3161472ab4",
    USJ: "ba65dd6153fb5a1660a4d95f3f65fcce35260acac7c53cfc39572ae5642a4ed5",
    HNM: "none"
}

const staticRoot = {
    SDJ: "https://static.swissdevjobs.ch",
    GDJ: "https://static.germantechjobs.de",
    NDJ: "https://static.devitjobs.nl",
    RDJ: "https://static.devjob.ro",
    UKJ: "https://static.devitjobs.uk",
    USJ: "https://static.devitjobs.com",
    HNM: "https://static.hiringnearme.jobs",
}

const LOGO_IMAGES = "/logo-images/"

const logo = {
    SDJ: staticRoot.SDJ + LOGO_IMAGES + "swissdev-jobs-transparent.png",
    GDJ: staticRoot.GDJ + LOGO_IMAGES + "germantech-jobs-transparent.png",
    NDJ: staticRoot.NDJ + LOGO_IMAGES + "devit-jobs-logo-v3.png",
    RDJ: staticRoot.RDJ + LOGO_IMAGES + "devjob-transparent.png",
    UKJ: staticRoot.UKJ + LOGO_IMAGES + "uk-dev-it-jobs-transparent.png",
    USJ: staticRoot.USJ + LOGO_IMAGES + "us-dev-it-jobs-transparent.png",
    HNM: staticRoot.HNM + LOGO_IMAGES + "hnm-logo-whiteblue.svg",
}

const fbLogo = {
    SDJ: staticRoot.SDJ + LOGO_IMAGES + "swissdev-logo-name-2-1.png",
    GDJ: staticRoot.GDJ + LOGO_IMAGES + "germantech-logo-name-2-1.png",
    NDJ: staticRoot.NDJ + LOGO_IMAGES + "dev-it-jobs-2-1-name.png",
    RDJ: staticRoot.RDJ + LOGO_IMAGES + "devjob-logo-name-2-1.png",
    UKJ: staticRoot.UKJ + LOGO_IMAGES + "dev-it-jobs-2-1-name.png",
    USJ: staticRoot.USJ + LOGO_IMAGES + "dev-it-jobs-2-1-name.png",
    HNM: staticRoot.HNM + LOGO_IMAGES + "hiring-near-me-facebook-2.png",
}

const stickerSrc = {
    SDJ: staticRoot.SDJ + LOGO_IMAGES + "swissdev-logo-name-square-small.png",
    GDJ: staticRoot.GDJ + LOGO_IMAGES + "germantech-logo-name-square-small.png",
    NDJ: staticRoot.NDJ + LOGO_IMAGES + "devitjobs-logo-big.png",
    RDJ: staticRoot.RDJ + LOGO_IMAGES + "devjob-logo-square-small.png",
    UKJ: staticRoot.UKJ + LOGO_IMAGES + "devitjobs-uk-logo-square.png",
    USJ: staticRoot.USJ + LOGO_IMAGES + "devitjobs-com-logo-square.png",
    HNM: "none"
}

const twitterProfile = {
    SDJ: "https://twitter.com/SwissDevJobs",
    GDJ: "https://twitter.com/GermanTechJobs",
    RDJ: "https://twitter.com/DevJobRo1",
    NDJ: "https://twitter.com/DevITJobsNL",
    UKJ: "https://twitter.com/DevITjobs",
    USJ: "https://twitter.com/DevITjobs",
}

const telegramProfile = {
    SDJ: "https://t.me/switzerlanddevjobs",
    GDJ: "https://t.me/germantechjobs",
    RDJ: "https://t.me/devjobro",
    NDJ: "https://t.me/devitjobs_nl",
    UKJ: "https://t.me/devitjobs",
    USJ: "https://t.me/devitjobs_us",
}

const facebookProfile = {
    SDJ: "https://www.facebook.com/swissdevjobs",
    GDJ: "https://www.facebook.com/GermanyTechJobs/",
    RDJ: "https://www.facebook.com/devjob.ro",
    NDJ: "https://www.facebook.com/DevITJobsNL",
    UKJ: "https://www.facebook.com/devitjobs",
    USJ: "https://www.facebook.com/devitjobs",
}

const linkedinProfile = {
    SDJ: "https://www.linkedin.com/company/swissdev-jobs",
    GDJ: "https://www.linkedin.com/company/germantech-jobs",
    RDJ: "https://www.linkedin.com/company/devjob-ro",
    NDJ: "https://www.linkedin.com/company/devjjobs",
    UKJ: "https://www.linkedin.com/company/devitjobs",
    USJ: "https://www.linkedin.com/company/devitjobs",
}

const discordServer = {
    SDJ: "https://discord.com/invite/MugtjEsjck",
    GDJ: "https://discord.com/invite/MugtjEsjck",
    RDJ: "https://discord.com/invite/MugtjEsjck",
    NDJ: "https://discord.com/invite/MugtjEsjck",
    UKJ: "https://discord.com/invite/MugtjEsjck",
    USJ: "https://discord.com/invite/MugtjEsjck",
}

const androidApp = {
    SDJ: "https://play.google.com/store/apps/details?id=ch.swissdevjobs.swissdevjobsAPP",
    GDJ: "https://play.google.com/store/apps/details?id=de.germantechjobs",
    RDJ: "https://play.google.com/store/apps/details?id=ro.devjob",
    UKJ: "https://play.google.com/store/apps/details?id=uk.devitjobs",
}

const iosApp = {
    SDJ: "https://apps.apple.com/us/app/swissdevjobs/id6467407240",
    GDJ: "https://apps.apple.com/us/app/germantechjobs/id6470950598",
    RDJ: "https://apps.apple.com/us/app/devjob-ro/id6479704986",
    UKJ: "https://apps.apple.com/us/app/devitjobs-uk/id6472210050",
}

const facebookGroup = {
    SDJ: "https://www.facebook.com/groups/swissdevjobsgroup",
    GDJ: "https://www.facebook.com/groups/germantechjobsgroup/",
    UKJ: "https://www.facebook.com/groups/devitjobsgroup/",
    USJ: "https://www.facebook.com/groups/devitjobsgroup/",
}

const tallySurveyLink = {
    SDJ: "https://tally.so/embed/wg5O7d?hideTitle=1&transparentBackground=1",
    GDJ: "https://tally.so/embed/mJ2BJd?hideTitle=1&transparentBackground=1",
    RDJ: "https://tally.so/embed/wQrRWY?hideTitle=1&transparentBackground=1",
    UKJ: "https://tally.so/embed/npLZ7b?hideTitle=1&transparentBackground=1",
    USJ: "https://tally.so/embed/wMAGJX?hideTitle=1&transparentBackground=1",
}

const squareLogo = {
    SDJ: "swissdev-jobs-logo.png",
    GDJ: "germantech-logo-name-square-small.png",
    NDJ: "devit-logo-square.png",
    RDJ: "devjob-logo-square-small.png",
    UKJ: "devit-logo-square.png",
    USJ: "devit-logo-square.png",
}

const mapCoordinates = {
    SDJ: {
        zoom: 8,
        lat: 46.759,
        lng: 8.0
    },
    GDJ: {
        zoom: 6.5,
        lat: 51.000,
        lng: 10.5000
    },
    NDJ: {
        zoom: 8,
        lat: 52.254,
        lng: 5.517
    },
    RDJ: {
        zoom: 7,
        lat: 45.991,
        lng: 24.654
    },
    UKJ: {
        zoom: 6.5,
        lat: 53.723,
        lng: -2.387
    },
    USJ: {
        zoom: 4,
        lat: 38.919,
        lng: -96.633
    },
    HNM: {
        zoom: 4,
        lat: 38.919,
        lng: -96.633
    },
}

const localeBase = {
    SDJ: '/:locale(en|de|fr)?',
    GDJ: '/:locale(en|de|fr)?',
    NDJ: '/:locale(en|nl)?',
    RDJ: '/:locale(en|ro)?',
    UKJ: '/:locale(en)?',
    USJ: '/:locale(en)?',
    HNM: '/:locale(en)?',
}

const baseLang = {
    SDJ: 'en',
    GDJ: 'de',
    NDJ: 'nl',
    RDJ: 'ro',
    UKJ: 'en',
    USJ: 'en',
    HNM: 'en',
}

const supportedLangs = {
    SDJ: ['en', 'de', 'fr'],
    GDJ: ['de', 'en'],
    NDJ: ['nl', 'en'],
    RDJ: ['ro', 'en'],
    UKJ: ['en'],
    USJ: ['en'],
    HNM: ['en'],
}

const languageFilters = {
    SDJ: ["All", "English", "French", "German"],
    GDJ: ["All", "English", "German"],
    NDJ: ["All", "Dutch", "English"],
    RDJ: [],
    UKJ: [],
    USJ: [],
}

const trustpilotUrl = {
    SDJ: "https://www.trustpilot.com/review/swissdevjobs.ch",
    GDJ: "https://www.trustpilot.com/review/germantechjobs.de",
    UKJ: "https://www.trustpilot.com/review/devitjobs.uk",
}

const hreflangCodes = {
    SDJ: {
        en: "en-CH",
        de: "de-CH",
        fr: "fr-CH"
    },
    GDJ: {
        en: "en-DE",
        de: "de-DE",
    },
    NDJ: {
        en: "en-NL",
        nl: "nl-NL",
    },
    RDJ: {
        en: "en-RO",
        ro: "ro",
    },
    UKJ: {
        en: "en-GB"
    },
    USJ: {
        en: "en-US"
    },
    HNM: {
        en: "en-US"
    },
}

const homePageTranslations = {
    SDJ: {
        de: {
            softwaredeveloper: "Softwareentwickler",
            country: "der Schweiz",
            countrish: "Schweizer",
            firstjobboard: " ist das erste IT-Entwickler Portal, das für",
            top1jobboard: "? Auf der Top 1 IT-Entwickler Portal finden Sie ",
            incountry: "in",
            incity: "in"
        },
        en: {
            softwaredeveloper: "Software Developer",
            country: "Switzerland",
            countrish: "Swiss",
            firstjobboard: " is the first IT developer portal built with",
            top1jobboard: "? On the Top 1 IT developer portal you can search ",
            incountry: "in",
            incity: "in"
        },
        fr: {
            softwaredeveloper: "Développeurs",
            country: "Suisse",
            countrish: "Suisse",
            firstjobboard: " est le premier portail pour développeurs informatiques construit avec",
            top1jobboard: "? Sur le portail des développeurs informatiques Top 1, vous pouvez rechercher ",
            incountry: "en",
            incity: "à"
        },
        nl: {},
        ro: {},
    },
    GDJ: {
        de: {
            softwaredeveloper: "Softwareentwickler",
            country: "Deutschland",
            countrish: "Deutsche",
            firstjobboard: " ist das erste Deutsche IT-Jobportal, das für",
            top1jobboard: "? Auf der Top 1 IT-Jobbörse Deutschland finden Sie ",
            incountry: "in",
            incity: "in"
        },
        en: {
            softwaredeveloper: "Software Developer",
            country: "Germany",
            countrish: "German",
            firstjobboard: " is the first German IT job board built with",
            top1jobboard: "? On the Top 1 German IT job board you can search ",
            incountry: "in",
            incity: "in"
        },
        fr: {},
        nl: {},
        ro: {},
    },
    NDJ: {
        en: {
            softwaredeveloper: "Software Developer",
            country: "the Netherlands",
            countrish: "Dutch",
            firstjobboard: " is the first Dutch IT job board built with",
            top1jobboard: "? On the Top 1 Dutch IT job board you can search ",
            incountry: "in",
            incity: "in"
        },
        nl: {
            softwaredeveloper: "Software Ontwikkelaar",
            country: "Nederland",
            countrish: "Nederlandse",
            firstjobboard: " is de eerste Nederlandse IT-vacaturebank gebouwd met",
            top1jobboard: "? Op de Top 1 Nederlandse IT vacaturesite kun je zoeken ",
            incountry: "in",
            incity: "in"
        },
        fr: {},
        de: {},
        ro: {},
    },
    RDJ: {
        en: {
            softwaredeveloper: "Software Developer",
            country: "Romania",
            countrish: "Romanian",
            firstjobboard: " is the first Romanian IT job board built with",
            top1jobboard: "? On the Top 1 Romanian IT job board you can search ",
            incountry: "in",
            incity: "in"
        },
        ro: {
            softwaredeveloper: "Dezvoltator de software",
            country: "Romania",
            countrish: "Romania",
            firstjobboard: " este primul portal de joburi in IT din Romania construit pe baza nevoilor",
            top1jobboard: "? Pe primul loc de munca IT din Romania poti cauta ",
            incountry: "in",
            incity: "în"
        },
        fr: {},
        de: {},
        nl: {},
    },
    UKJ: {
        en: {
            softwaredeveloper: "Software Developer",
            country: "the UK",
            countrish: "British",
            firstjobboard: " is the first job board built with",
            top1jobboard: "? On the Top 1 UK job board you can search ",
            incountry: "in",
            incity: "in"
        },
        fr: {},
        de: {},
        nl: {},
        ro: {},
    },
    USJ: {
        en: {
            softwaredeveloper: "Software Developer",
            country: "the US",
            countrish: "US",
            firstjobboard: " is the first job board built with",
            top1jobboard: "? On the Top 1 US job board you can search ",
            incountry: "in",
            incity: "in"
        },
        fr: {},
        de: {},
        nl: {},
        ro: {},
    },
    HNM: {
        en: {
            country: "United States",
            firstjobboard: " is the first job board built with",
            top1jobboard: "? On the Top 1 U.S. job board you can search ",
            incountry: "in",
            incity: "in"
        },
    }
}

const adPrice = {
    SDJ: '-',
    GDJ: '390 EUR',
    NDJ: '340 EUR',
    RDJ: '-',
    UKJ: '-',
    USJ: '-',
    HNM: '-',
}

const visitorsNumber = {
    SDJ: "80'000",
    GDJ: "50.000",
    NDJ: "12'000",
    RDJ: "20'000",
    UKJ: "24'000",
    USJ: "125'000",
    HNM: '-',
}

const candidatesNumber = {
    SDJ: "32",
    GDJ: "36",
    NDJ: "26",
    RDJ: "59",
    UKJ: "35",
    USJ: "39",
}
const publicContactPhone = {
    SDJ: "+41 77 444 07 34",
    GDJ: "+49 159 0162 3060",
    NDJ: "", //dont want
    RDJ: "", //dont want
    UKJ: "+44 7389 673140",
    USJ: "+1 (434) 474-1159",
}

const jobsNumber = {
    SDJ: "300",
    GDJ: "800",
    NDJ: "200",
    RDJ: "400",
    UKJ: "700",
    USJ: "2250",
}

const newsletterNumber = {
    SDJ: "11'200",
    GDJ: "5.900",
    NDJ: "1'000",
    RDJ: "2'100",
    UKJ: "3'000",
    USJ: "1'200",
}

const followerNumber = {
    SDJ: "28'000",
    GDJ: "15.000",
    NDJ: "5'000",
    RDJ: "6'000",
    UKJ: "14'000",
    USJ: "14'000",
}

const companiesNumber = {
    SDJ: "1600",
    GDJ: "1600",
    NDJ: "400",
    RDJ: "300",
    UKJ: "1600",
    USJ: "1600",
}

const termsConditionsAddress = {
    SDJ: "/documents/Terms-And-Conditions-2024.pdf",
    GDJ: "/documents/Terms-And-Conditions-2024.pdf",
    NDJ: "/documents/Terms-And-Conditions-2024.pdf",
    RDJ: "/documents/Terms-And-Conditions-2024.pdf",
    UKJ: "/documents/Terms-And-Conditions-2024.pdf",
    USJ: "/documents/Terms-And-Conditions-2024.pdf",
}

const techCategories = ["C++", "dotNET", "Data", "Golang", "Java", "JavaScript", "Mobile", "PHP", "Python", "Ruby", "SAP", "System", "DevOps", "IT", "Database", "Tester", "Machine-Learning", "Security", "Network", "GameDev", "Blockchain", "UI-UX-Designer", "Architect", "Business", "Manager", "Support", "Rust"]

const additionalTechCategories = urlTechTags

const aggregatedCompanies = ["Amazon Workforce Staffing", "Ascension", "PepsiCo", "International Paper", "WestRock", "FedEx Ground PH US", "TruGreen", "KOHLS"]
const companyUrls = {
    "amazon-workforce-staffing": "Amazon Workforce Staffing",
    "ascension": "Ascension",
    "pepsico": "PepsiCo",
    "international-paper": "International Paper",
    "westrock": "WestRock",
    "fedex-ground-ph-us": "FedEx Ground PH US",
    "trugreen": "TruGreen",
    "kohls": "KOHLS",
}

const maxSalary = {
    SDJ: "165'000 CHF",
    GDJ: "110.000 Euro",
    NDJ: "100.000 Euro",
    RDJ: "33.000 RON",
    UKJ: "125,000£",
    USJ: "220,000$",
}

const brandCalendar = {
    SDJ: "SwissDevCalendar",
    GDJ: "GermanTechCalendar",
    NDJ: "DevITCalendar",
    RDJ: "DevJobCalendar",
    UKJ: "DevITCalendar",
    USJ: "DevITCalendar",
}

const adzunaId = {
    SDJ: "https://www.adzuna.ch/app_complete/9550",
    GDJ: "https://www.adzuna.de/app_complete/8722",
    UKJ: "https://www.adzuna.co.uk/app_complete/9551",
    USJ: "https://www.adzuna.com/app_complete/9552",
}

const twttrPxlId = {
    SDJ: "odc0b",
    GDJ: "odc0l",
    NDJ: null,
    RDJ: "odebi",
    UKJ: null,
    USJ: null,
}

const fbPxlId = {
    SDJ: "1258381018384581",
    GDJ: "2329239997283400",
    NDJ: null,
    RDJ: "1235962217329326",
    UKJ: "1198124474432220",
    USJ: "1333501340808334",
}

const fbVerificationId = {
    SDJ: "oukziivm79z0t0xye7uruesozu72z0",
    GDJ: "9gwhra55g6zznndc2jg77un80ah5vv",
    NDJ: null,
    RDJ: "blykw51b75lgs2qp44ztc4j024fw1u",
    UKJ: "bk3kk1vk1bv2ee1kcs21f5df4qcadf",
    USJ: "t48d5mhivlsvwfznduxi5mj4ddx3pe",
}

function Config(siteName) {
    if (!siteName) siteName = process.env.REACT_APP_SITE_NAME || LOCAL_SITE_NAME
    this.TECH_CATEGORIES = techCategories
    this.ADDITIONAL_TECH_CATEGORIES = additionalTechCategories
    this.SITE_NAME = siteName
    this.CITY_CATEGORIES = cityCategories[siteName]
    this.ANDROID_APP_URL = androidApp[siteName]
    this.IOS_APP_URL = iosApp[siteName]
    this.STATE_CATEGORIES = stateCategories[siteName]
    this.PAGE_ROOT = pageRoot[siteName]
    this.JOB_APP_ROOT = jobAppRoot[siteName]
    this.FULL_NAME = fullName[siteName]
    this.CURRENCY = currency[siteName]
    this.COUNTRY_CODE = countryCode[siteName]
    this.COUNTRY = country[siteName]
    this.IN_COUNTRY = inCountry[siteName]
    this.COUNTRISH = countrish[siteName]
    this.TAWK_ID = tawkId[siteName]
    this.WONDERPUSH_PUBLIC_KEY = wonderpushPublicKey[siteName]
    this.STATIC_ROOT = staticRoot[siteName]
    this.TRANSLATIONS = homePageTranslations[siteName]
    this.LOGO_SRC = logo[siteName]
    this.FB_LOGO = fbLogo[siteName]
    this.STICKER_SRC = stickerSrc[siteName]
    this.TWITTER_PROFILE = twitterProfile[siteName]
    this.TALLY_SURVEY_LINK = tallySurveyLink[siteName]
    this.TELEGRAM_PROFILE = telegramProfile[siteName]
    this.FACEBOOK_PROFILE = facebookProfile[siteName]
    this.LINKEDIN_PROFILE = linkedinProfile[siteName]
    this.FACEBOOK_GROUP = facebookGroup[siteName]
    this.SQUARE_LOGO = squareLogo[siteName]
    this.MAP_COORDINATES = mapCoordinates[siteName]
    this.LOCALE_BASE = localeBase[siteName]
    this.BASE_LANG = baseLang[siteName]
    this.SUPPORTED_LANGS = supportedLangs[siteName]
    this.HREFLANG_CODES = hreflangCodes[siteName]
    this.MAX_SALARY_STAT = maxSalary[siteName]
    this.AD_PRICE = adPrice[siteName]
    this.COMPANIES_NUMBER = companiesNumber[siteName]
    this.VISITORS_NUMBER = visitorsNumber[siteName]
    this.CANDIDATES_NUMBER = candidatesNumber[siteName]
    this.JOBS_NUMBER = jobsNumber[siteName]
    this.PUBLIC_CONTACT_PHONE = publicContactPhone[siteName]
    this.LANGUAGE_FILTERS = languageFilters[siteName]
    this.AGGREGATED_COMPANIES = aggregatedCompanies
    this.COMPANY_URLS = companyUrls
    this.NEWSLETTER_SUB_NUMBER = newsletterNumber[siteName]
    this.SOCIAL_FOLLOWERS = followerNumber[siteName]
    this.TRUSTPILOT_URL = trustpilotUrl[siteName]
    this.BRAND_CALENDAR = brandCalendar[siteName]
    this.ADZUNA_URL = adzunaId[siteName]
    this.DISCORD_SERVER = discordServer[siteName]
    this.TWTTR_PXL_ID = twttrPxlId[siteName]
    this.FB_PXL_ID = fbPxlId[siteName]
    this.FB_VERIFICATION_ID = fbVerificationId[siteName]
    this.TERMS_CONDITIONS_ADDRESS = termsConditionsAddress[siteName]
    this.NO_CACHE_URL_MODIFIER = "?no_cache_please" // custom cache rule in Cloudflare
}

const siteConfig = new Config()

module.exports = {
    Config,
    siteConfig,
}