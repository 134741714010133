import { siteConfig } from './siteConfig'

const { BASE_LANG, SUPPORTED_LANGS, PAGE_ROOT, NEWSLETTER_SUB_NUMBER, FULL_NAME, AD_PRICE, SOCIAL_FOLLOWERS, TRANSLATIONS } = siteConfig

class Translator {
  constructor(locale, dictionary) {
    this.locale = locale ? locale : BASE_LANG;

    if (Array.isArray(dictionary)) {
      const activeTranslations = {}
      for (const lang of SUPPORTED_LANGS) {
        for (const dict of dictionary)
          if (dict) {
            activeTranslations[lang] = {
              ...activeTranslations[lang], ...dict[lang]
            }
          }
      }
      this.dictionary = activeTranslations
    } else {
      this.dictionary = dictionary;
    }
  }
  t = (keyword) => {
    return this.dictionary[this.locale] && this.dictionary[this.locale][keyword]
  }
}

const rootWithLocale = lang => {
  return PAGE_ROOT + (!lang || lang === BASE_LANG ? "" : "/" + lang)
}

const commonTranslations = {
  de: {
    All: "Alle",
    softwareengineer: "Softwareingenieur",
    softwaredeveloper: "Softwareentwickler",
    jobs: "Stellenangebote",
    geta: "Erhalten Sie",
    weekly: "wöchentlich",
    emailwith: "eine E-Mail mit den neuesten",
    joboffers: "Stellenangeboten",
    wepromise: "Wir versprechen, Ihnen nur den wöchentlichen Job-Newsletter zuzusenden.",
    yourname: "Ihr Vor- und Nachname",
    email: "Ihre E-Mail",
    close: "Zurück",
    subscribe: "Abonnieren",
    bysendingyourdata: "Mit dem Absenden Ihrer Kontaktdaten erklären Sie sich mit der Datenverarbeitung durch den Administrator SwissDevJobs einverstanden. Der Administrator verarbeitet die Daten gemäss den",
    bysendingyourdataGDJ: "Mit dem Absenden Ihrer Kontaktdaten erklären Sie sich mit der Datenverarbeitung durch den Administrator GermanTechJobs einverstanden. Der Administrator verarbeitet die Daten gemäss den",
    privacypolicy: "Datenschutzbestimmungen",
    thankyousubscribing: "Danke fürs Abonnieren.",
    confirmemail: "Bitte bestätigen Sie Ihren wöchentlichen Newsletter, indem Sie auf den Link klicken, den Sie erhalten haben.",
    Contract: "Freelance / Contract",
    Internship: "Praktikum",
    "Part-Time": "Teilzeit",
    "Full-Time": "Vollzeit",
    remote: "Remote",
    hybrid: "Hybrid",
    office: "Vor Ort",
    anywhere: "Weltweit",
    countryandeu: "In " + TRANSLATIONS.de.country + " und EU",
    onlycountry: "Nur in " + TRANSLATIONS.de.country,
    English: "Englisch",
    German: "Deutsch",
    Italian: "Italienisch",
    French: "Französisch",
    Product: "Produkt",
    Services: "Agentur / Consulting",
    Startup: "Startup",
    pricing: "Leistungs- und Preisübersicht",
    pricingshort: "Preise",
    whatisincluded: "Was ist in der Stellenanzeige enthalten",
    publicationduration: "Laufzeit",
    publishjob: "Stelle publizieren",
    bestvalue: "Beliebteste Option",
    tryforfree: "Kostenlos testen",
    youcantryfree: "Sie können unsere Jobbörse mit 2 Basic-Stellenanzeigen völlig kostenlos ausprobieren",
    durationhint: "Mit Business oder Premium ist Ihre Stelle so lange aktiv, bis Sie die richtige Person finden und die Stellenanzeige deaktivieren",
    unlimited: "Unbegrenzt",
    days: "Tage",
    refreshbump: "Refresh (an den Anfang der Liste)",
    refreshhint: "Ihr Job wird alle 30 Tage aktualisiert und an die Spitze der Liste geschoben (Basic einmal nach 15 Tagen)",
    once: "Einmal",
    multiple: "Mehrmals",
    numberoflocations: "Arbeitsorte",
    locationshint: "Anzahl der Städte, in denen Ihre Stelle ausgeschrieben wird – perfekt bei mehreren Standorten",
    upto5: "Bis zu 5",
    individualsupport: "Individuelle Kundenbetreuung",
    supporthint: "Senden Sie uns jederzeit eine Nachricht und wir werden versuchen, Ihnen zu helfen! :)",
    promoinapp: "Extra Veröffentlichung in unserer Mobile-App",
    placedinourapps: "Ihre Stellenanzeigen werden in unseren offiziellen Mobile-Apps (Android & iOS) veröffentlicht.",
    includednewsletter: "Versand im Job-Newsletter",
    subscribers: "Versand im wöchentlichen Job-Newsletter an über " + NEWSLETTER_SUB_NUMBER + " Abonnenten",
    socialmediapromo: "Social Media Promotion",
    overfollowers: "Publiziert auf unseren LinkedIn, Twitter und Facebook - insgesamt über " + SOCIAL_FOLLOWERS + " Follower.",
    adreview: "Anzeigenüberprüfung und Optimierung",
    editorialreview: "Unser Redaktionsteam wird Ihre Anzeige überprüfen und Vorschläge machen, wie sie verbessert werden kann, um die beste Leistung zu gewährleisten.",
    tcp: "Transparentes Arbeitgeberprofil",
    tcphint: "Eine tolle Möglichkeit, mehr über Ihre Unternehmensgeschichte, Kultur und Teammitglieder zu erzählen. Sie können Bilder und Videos Ihrer Wahl einfügen. Klicken Sie auf den Link, um die Profile unserer transparenten Unternehmen zu überprüfen!",
    customdesign: "Individuelle Gestaltungsmöglichkeiten",
    activeuntil: "aktiv, bis Sie den richtigen Kandidaten gefunden und die Stellenanzeige geschlossen haben",
    iframewith: "Iframe mit Ihrem Design, das in der Stellenbeschreibung angezeigt wird",
    morejobsorstartup: "Möchten Sie mehrere Jobs veröffentlichen oder sind Sie ein Startup?",
    planningmorejobs: "Planen Sie mehr Stellenanzeigen?",
    discountsforstartups: "wir bieten Discounts für Startups und NPOs an",
    contactus: "Kontaktieren Sie uns",
    companyname: "Unternehmen",
    yourname: "Ihr Vor- und Nachname",
    youremail: "Ihre E-Mail",
    phonenumber: "Telefonnummer",
    password: "Passwort",
    message: "Ihre Nachricht",
    jobsnumber: "Anzahl von Stellen",
    wearestartup: "Wir sind ein Startup",
    consideredstartup: "Als Startup gelten Sie, wenn Ihr Unternehmen vor weniger als 5 Jahren gegründet wurde und Sie weniger als 50 Mitarbeiter haben",
    send: "Schicken",
    thankyou: "Vielen Dank für die Anfrage!",
    wewillcontact: "Wir melden uns in Kürze bei Ihnen zurück.",
    tailoredoffer: "Kontaktieren Sie uns für massgeschneiderte Angebote und spezielle Rabatte für Start-Ups und NPOs",
    notconvinced: "Nicht sicher, ob " + FULL_NAME + " die richtige Wahl für Sie ist?",
    notconvincedcat: "Verlassen Sie uns schon? Bringen Sie unser kleines Kätzchen nicht zum Weinen... 😦",
    leaveyourquestion: "Hinterlassen Sie Ihre Frage / Nachricht und unser Account Manager wird sich innerhalb von 2 Stunden bei Ihnen melden.",
    leaveyourquestioncat: "Sie sind sich über unser Angebot nicht sicher? Senden Sie uns Ihre Fragen – wir werden Ihnen innerhalb von 2 Stunden antworten.",
    questionmessage: "Ihre Frage / Nachricht",
    ourreviewstrust: "Unsere Kundenbewertungen auf",
    visasponsorship: "Visa Sponsoring",
    yes: "Ja ",
    no: "Nein ",
    ingermany: "in Deutschland",
    inswitzerland: "in der Schweiz",
    inromania: "in Rumänien",
    inuk: "in Großbritannien",
    inusa: "in den Vereinigten Staaten",
    innetherlands: "in den Niederlanden",
    freeofcarge: "Wir treten auf den deutschen Markt ein und daher können Sie Ihre Stellenausschreibungen kostenlos bei uns hinzufügen.",
    onejob: "Ihre Stellenausschreibung kostet nur " + AD_PRICE,
    daysactive: "Unbegrenzte Laufzeit",
    perjobad: "pro Inserat",
    onwebsite: " auf unserer Website, bis Sie die richtigen Kandidaten einstellen",
    presence: "Gesendet im",
    newsletter: "Job-Newsletter an über 3000 IT-Profis",
    broadcasted: "Publiziert in ",
    andour: "und auf unseren",
    partnerjobboards: "Partner-Jobbörsen",
    hiringorg: "einstellende Organisation",
    hiringorgaddress: "Adresse der einstellenden Organisation",
    salaryrange: "jährliche Gehaltsspanne",
    applybtn: "Bewerben Knopf",
    jobalertbtn: "Job-Alert Knopf",
    developer: "Entwickler",
    developers: "Entwickler",
    engineer: "Ingenieur",
    engineers: "Ingenieure",
    designer: "Designer",
    designers: "Designer",
    specialist: "Spezialist",
    specialists: "Spezialisten",
    blog: "Blog & Karrieretipps",
    perhour: "Stunde",
    perday: "Tag",
    enjoybfdeal: "Profitieren Sie von 50% Black-Friday-Rabatt auf Ihre erste Stellenanzeige mit dem Code BFDEAL (gültig bis 15. Dezember)"
  },
  en: {
    All: "All",
    softwareengineer: "Software Engineer",
    softwaredeveloper: "Software Developer",
    jobs: "jobs",
    job: "job",
    geta: "Get a",
    weekly: "weekly",
    emailwith: "email with the newest",
    joboffers: "job offers",
    wepromise: "We promise that we will only send you the weekly job newsletter.",
    yourname: "Your name",
    email: "Your email",
    close: "Close",
    subscribe: "Subscribe",
    bysendingyourdata: "By sending your contact details you agree to data processing by the administrator: SwissDevJobs. The administrator processes data following the",
    bysendingyourdataGDJ: "By sending your contact details you agree to data processing by the administrator: GermanTechJobs. The administrator processes data following the",
    privacypolicy: "Privacy Policy.",
    thankyousubscribing: "Thank you for subscribing.",
    confirmemail: "Please confirm your weekly newsletter by clicking on the link you received.",
    Contract: "Freelance / Contract",
    Internship: "Internship",
    "Part-Time": "Part-Time",
    "Full-Time": "Full-Time",
    remote: "Remote",
    hybrid: "Hybrid",
    office: "Office",
    anywhere: "Worldwide",
    countryandeu: TRANSLATIONS.en.country + " and EU",
    onlycountry: "Only in " + TRANSLATIONS.en.country,
    English: "English",
    Dutch: "Dutch",
    German: "German",
    Italian: "Italian",
    French: "French",
    Product: "Product",
    Services: "Agency / Services",
    Startup: "Startup",
    pricing: "Pricing",
    pricingshort: "Pricing",
    whatisincluded: "What is included in the single job ad",
    whatisincludedbundle: "What is included in the bundle",
    publicationduration: "Duration of publication",
    publishjob: "Post a job",
    bestvalue: "Most popular",
    tryforfree: "Try for free",
    packagecontains5: "This package contains 5 job ads, and this is your price per job ad.",
    packagecontains10: "This package contains 10 job ads, and this is your price per job ad.",
    youcantryfreero: "You can try our job board with 1 Basic job ad, completely free of charge",
    youcantryfree: "You can try our job board with 3 Basic job ads, completely free of charge",
    durationhint: "With Business and Premium your job is active until you find the right candidate and close the job ad",
    unlimited: "Unlimited",
    days: "days",
    months: "months",
    refreshbump: "Refresh to the top",
    refreshhint: "Your job will be refreshed and bumped to the top of the list every 30 days (for Basic once after 15 days)",
    once: "Once",
    multiple: "Multiple",
    numberoflocations: "Job locations",
    locationshint: "Your job will be advertised in several cities - perfect for companies with multiple office locations",
    numberofjobs: "Number of job ads included",
    numberofjobshint: "How many single job ads are included in the bundle",
    upto5: "Up to 5",
    individualsupport: "Individual Customer Support",
    supporthint: "Message us at any time and we will do our best to help! :)",
    promoinapp: "Extra publication in our mobile app",
    placedinourapps: "Your job ads will be published in our official mobile apps (Android & iOS)",
    includednewsletter: "Included in the job newsletter",
    subscribers: "Sent in the weekly job newsletter to over " + NEWSLETTER_SUB_NUMBER + " subscribers",
    socialmediapromo: "Social Media Promotion",
    overfollowers: "Promoted on our Social Media (LinkedIn, Twitter, and Facebook) - over " + SOCIAL_FOLLOWERS + " followers in total.",
    adreview: "Ad Review & Optimization",
    editorialreview: "Our editorial team will review your ad and suggest how it can be improved to guarantee the best performance.",
    tcp: "Transparent Company Profile",
    relevantmagazines: "Job posting in relevant magazines",
    tcphint: "A great way to tell more about your company story, culture and team members. You can include pictures and videos of your choice. Click the link to check profiles of our Transparent Companies!",
    customdesign: "Custom design options",
    activeuntil: "active until you find the right candidate and close the job ad",
    iframewith: "Embed your original design (using an iFrame)",
    morejobsorstartup: "Do you want to publish multiple jobs or are you a startup?",
    planningmorejobs: "Planning more job openings?",
    discountsforstartups: "we offer discounts for early stage Startups and Non-Profits",
    largejobsorstartup: "Do you want to publish a large number of jobs or are you a startup?",
    contactus: "Contact Us",
    companyname: "Company name",
    yourname: "Your name",
    youremail: "Your email",
    phonenumber: "Phone number",
    password: "Password",
    message: "Your message",
    jobsnumber: "Jobs number",
    wearestartup: "We are a startup",
    consideredstartup: "You are considered a startup if your company was started less than 5 years ago and you have less than 50 employees",
    send: "Send",
    thankyou: "Thank you for the inquiry!",
    wewillcontact: "We will get back to you shortly.",
    tailoredoffer: "Contact us for tailor-made offers and special discounts for start-ups and non-profits",
    notconvinced: "Not sure if " + FULL_NAME + " is the right choice for you? ",
    notconvincedcat: "Already leaving us? Don't make our little kitty cry... 😦",
    leaveyourquestion: "Leave your question / message and our representative will get back to you within 2 hours.",
    leaveyourquestioncat: "Not sure about our offer? Ask us anything - we will get back to you within 2 hours.",
    questionmessage: "Your question / message",
    ourreviewstrust: "See our Customer Reviews on",
    visasponsorship: "Visa sponsorship",
    yes: "Yes ",
    no: "No ",
    ingermany: "in Germany",
    inswitzerland: "in Switzerland",
    inromania: "in Romania",
    inuk: "in the United Kingdom",
    inusa: "in the United States",
    innetherlands: "in the Netherlands",
    freeofcarge: "We are entering the German market, and therefore you can add your job postings on GermanTechJobs free of charge.",
    freeofcargeuk: "We are still growing on the UK market, and therefore you can add your job postings on DevITjobs free of charge.",
    freeofcargeus: "We are still growing on the US market, and therefore you can add your job postings on DevITjobs free of charge.",
    freeofcargeromania: "You can post your IT and developer jobs free of charge because we are growing our DevJob.ro brand.",
    onejob: "Single job posting costs only " + AD_PRICE,
    daysactive: "Unlimited time live",
    perjobad: "per job ad",
    onwebsite: "on our website until you hire the right candidates",
    presence: "Promoted in the",
    newsletter: "job newsletter with over 3000 IT professionals",
    broadcasted: "Broadcasted in ",
    andour: "and on our",
    partnerjobboards: "partner job boards",
    hiringorg: "hiring organization",
    hiringorgaddress: "hiring organization address",
    salaryrange: "annual salary range",
    applybtn: "apply button",
    jobalertbtn: "job alert button",
    developer: "Developer",
    developers: "Developers",
    engineer: "Engineer",
    engineers: "Engineers",
    designer: "Designer",
    designers: "Designers",
    specialist: "Specialist",
    specialists: "Specialists",
    blog: "Blog and Career Tips",
    perhour: "hour",
    perday: "day",
    enjoybfdeal: "Enjoy a 50% Black Friday Discount for your 1st job ad with the code BFDEAL (valid till 15. December)"
  },
  fr: {
    All: "Tout",
    softwareengineer: "Développeurs",
    softwaredeveloper: "Développeurs",
    jobs: "offres d'emploi",
    job: "offre d'emploi",
    geta: "Recevez un",
    weekly: "email hebdomadaire",
    emailwith: "avec les dernières",
    joboffers: "offres d'emploi",
    wepromise: "Nous vous promettons de ne vous envoyer que la newsletter hebdomadaire de l'emploi.",
    yourname: "Votre nom",
    email: "Email",
    close: "Ferme",
    subscribe: "Souscrire",
    bysendingyourdata: "En envoyant vos coordonnées, vous acceptez le traitement des données par l'administrateur: SwissDevJobs. L'administrateur traite les données après la",
    bysendingyourdataGDJ: "En envoyant vos coordonnées, vous acceptez le traitement des données par l'administrateur: GermanTechJobs. L'administrateur traite les données après la",
    privacypolicy: "politique de confidentialité.",
    thankyousubscribing: "Merci de vous être abonné.",
    confirmemail: "Veuillez confirmer votre newsletter hebdomadaire en cliquant sur le lien que vous avez reçu.",
    Contract: "Free-lance / Indépendant",
    Internship: "Stage",
    "Part-Time": "Temps partiel",
    "Full-Time": "Plein temps",
    remote: "Remote",
    hybrid: "Hybrid",
    office: "Bureau",
    anywhere: "Mondial",
    countryandeu: "En " + TRANSLATIONS.fr.country + " et dans l'UE",
    onlycountry: "Uniquement en " + TRANSLATIONS.fr.country,
    English: "Anglaise",
    German: "Allemande",
    Italian: "Italienne",
    French: "Française",
    Product: "Produit",
    Services: "Agence / Consulting",
    Startup: "Startup",
    pricing: "Aperçu des performances et des prix",
    pricingshort: "Tarification",
    whatisincluded: "Ce qui est inclus dans l'offre d'emploi",
    publicationduration: "Durée",
    publishjob: "Publier",
    bestvalue: "Populaire",
    tryforfree: "Essayer gratuitement",
    youcantryfree: "Vous pouvez essayer notre job board avec 2 offres d'emploi Basic, entièrement gratuites",
    durationhint: "Avec Business ou Premium, votre offre d'emploi reste active jusqu'à ce que vous trouviez la bonne personne et que vous désactiviez l'annonce",
    unlimited: "Illimité",
    days: "jours",
    refreshbump: "Actualiser (en haut de la liste)",
    refreshhint: "Votre annonce est mise à jour tous les 30 jours et remontée en tête de liste (Basic une fois après 15 jours).",
    once: "Une fois",
    multiple: "Plusieurs fois",
    numberoflocations: "Lieux de travail",
    locationshint: "Nombre de villes dans lesquelles votre offre est publiée – parfait pour plusieurs sites.",
    upto5: "Jusqu'à 5",
    individualsupport: "Support client individuel",
    supporthint: "Envoyez-nous un message à tout moment et nous essaierons de vous aider! :)",
    promoinapp: "Publication supplémentaire dans notre application mobile",
    placedinourapps: "Vos offres d'emploi seront publiées dans nos applications mobiles officielles (Android et iOS)",
    includednewsletter: "Envoyé dans la newsletter emploi",
    subscribers: "Envoyé dans la newsletter hebdomadaire sur l'emploi à plus de " + NEWSLETTER_SUB_NUMBER + " abonnés",
    socialmediapromo: "Promotion des médias sociaux",
    overfollowers: "Promu sur nos réseaux sociaux (LinkedIn, Twitter et Facebook) - plus de " + SOCIAL_FOLLOWERS + " abonnés au total.",
    adreview: "Révision et optimisation des annonces",
    editorialreview: "Notre équipe éditoriale examinera votre annonce et suggérera comment elle peut être améliorée pour garantir les meilleures performances.",
    tcp: "Profil Employeur Transparent",
    tcphint: "Un excellent moyen d'en dire plus sur l'histoire, la culture et les membres de votre équipe. Vous pouvez inclure des photos et des vidéos de votre choix. Cliquez sur le lien pour consulter les profils de nos entreprises transparentes!",
    customdesign: "Options de conception personnalisées",
    activeuntil: "actif jusqu'à ce que vous trouviez le bon candidat et fermiez l'offre d'emploi",
    iframewith: "Iframe avec votre design original affiché dans la description du poste",
    morejobsorstartup: "Vous souhaitez publier plusieurs offres d'emploi ou vous êtes une startup?",
    planningmorejobs: "Planifiez-vous davantage d'offres d'emploi?",
    discountsforstartups: "nous offrons réduction pour les start-ups et les ONG",
    contactus: "Nous contacter",
    companyname: "Nom de l'entreprise",
    yourname: "Votre nom",
    youremail: "Email",
    phonenumber: "Numéro de téléphone",
    password: "Mot de passe",
    message: "Votre message",
    jobsnumber: "Nombre d'emplois",
    wearestartup: "Nous sommes une startup",
    consideredstartup: "Vous êtes considéré comme une startup si votre entreprise a été créée il y a moins de 5 ans et que vous avez moins de 50 salariés",
    send: "Envoyer",
    thankyou: "Merci pour l'enquête!",
    wewillcontact: "Nous vous revenons sous peu.",
    tailoredoffer: "Contactez-nous pour des offres sur mesure et des réductions spéciales pour les start-ups et les ONG 😉",
    notconvinced: "Vous ne savez pas si " + FULL_NAME + " est le bon choix pour vous?",
    notconvincedcat: "Nous quitte déjà ? Ne faites pas pleurer notre petit minou... 😦",
    leaveyourquestion: "Laissez votre question/message et notre représentant vous répondra dans les 2 heures.",
    leaveyourquestioncat: "Vous n'êtes pas sûr de notre offre ? Envoyez-nous les questions - nous vous répondrons dans les 2 heures.",
    questionmessage: "Votre question/message",
    ourreviewstrust: "Nos avis clients sur",
    visasponsorship: "Parrainage de visa",
    yes: "Oui ",
    no: "Non ",
    ingermany: "en Allemagne",
    inswitzerland: "en Suisse",
    inromania: "en Roumanie",
    inuk: "au Royaume-Uni",
    inusa: "aux Etats-Unis",
    innetherlands: "aux Pays-Bas",
    hiringorg: "organisation d'embauche",
    hiringorgaddress: "adresse de l'organisme d'embauche",
    salaryrange: "fourchette de salaire annuel",
    applybtn: "bouton appliquer",
    jobalertbtn: "bouton d'alerte d'emploi",
    perjobad: "par offre d'emploi",
    developer: "Développeur",
    developers: "Développeurs",
    engineer: "Ingénieur",
    engineers: "Ingénieurs",
    designer: "Designer",
    specialist: "Spécialiste",
    specialists: "Spécialistes",
    designers: "Concepteurs",
    blog: "Blog & Conseils de carrière",
    perhour: "heure",
    perday: "jour",
    enjoybfdeal: "Profitez de 50% de réduction Black Friday sur votre première annonce d’emploi avec le code BFDEAL (valable jusqu’au 15 décembre)."
  },
  nl: {
    All: "Alle",
    softwareengineer: "Software Ontwikkelaar",
    softwaredeveloper: "Software Ontwikkelaar",
    jobs: "vacatures",
    job: "baan",
    geta: "Ontvang",
    weekly: "wekelijks",
    emailwith: "een e-mail met de nieuwste",
    joboffers: "vacatures",
    wepromise: "We beloven je dat we je alleen de wekelijkse vacaturenieuwsbrief zullen sturen.",
    yourname: "Uw naam",
    email: "Jouw email",
    close: "Sluit",
    subscribe: "Abonneren",
    bysendingyourdata: "Door jouw contactgegevens te verzenden, ga je akkoord met de gegevensverwerking door de beheerder: DevITJobs. De beheerder verwerkt gegevens volgens het",
    privacypolicy: "Privacybeleid.",
    thankyousubscribing: "Bedankt voor het abonneren.",
    confirmemail: "Bevestig uw wekelijkse nieuwsbrief door op de link te klikken die u heeft ontvangen.",
    Contract: "Freelance / Contract",
    Internship: "Stage",
    "Part-Time": "Deeltijd",
    "Full-Time": "Full-Time",
    remote: "Remote",
    hybrid: "Hybrid",
    office: "Kantoor",
    anywhere: "Wereldwijd",
    countryandeu: "In " + TRANSLATIONS.nl.country + " en de EU",
    onlycountry: "Alleen in " + TRANSLATIONS.nl.country,
    English: "Engels",
    Dutch: "Nederlands",
    German: "Duitse",
    Italian: "Italiaanse",
    French: "Frans",
    Product: "Product",
    Services: "Agentschap / Diensten",
    Startup: "Startup",
    pricing: "Prijzen",
    pricingshort: "Prijzen",
    whatisincluded: "Wat staat er in de vacature",
    publicationduration: "Looptijd",
    publishjob: "Vacature plaatsen",
    bestvalue: "Populairste",
    tryforfree: "Probeer gratis",
    youcantryfree: "U kunt onze vacaturebank met 2 basisvacatures helemaal gratis uitproberen",
    durationhint: "Met de onbeperkte optie is uw vacature actief totdat u de juiste kandidaat vindt en de vacature sluit.",
    unlimited: "Onbeperkt",
    days: "dagen",
    months: "maanden",
    refreshbump: "Vernieuwen (naar de bovenkant van de lijst)",
    refreshhint: "Uw vacature wordt elke 30 dagen vernieuwd en bovenaan de lijst geplaatst",
    once: "Eenmalig",
    multiple: "Meermalig",
    numberoflocations: "Werklocaties",
    locationshint: "Uw vacature wordt in verschillende steden geadverteerd - perfect voor bedrijven met meerdere kantoorlocaties",
    upto5: "Tot 5",
    numberofjobs: "Aantal vacatures",
    numberofjobshint: "hoeveel losse vacatures zijn er opgenomen in dit pakket",
    individualsupport: "Individuele klantenondersteuning",
    supporthint: "Stuur ons op elk moment een bericht en we zullen proberen je te helpen! :)",
    includednewsletter: "Verzonden in de vacaturenieuwsbrief",
    promoinapp: "Extra publicatie in onze mobiele app",
    placedinourapps: "Uw vacatures worden gepubliceerd in onze officiële mobiele apps (Android en iOS)",
    subscribers: "Verzonden in de wekelijkse vacaturenieuwsbrief naar meer dan " + NEWSLETTER_SUB_NUMBER + " abonnees",
    socialmediapromo: "Social Media Promotie",
    overfollowers: "Gepromoot op onze sociale media (LinkedIn, Twitter en Facebook) - in totaal meer dan " + SOCIAL_FOLLOWERS + " volgers.",
    adreview: "Advertentiebeoordeling en -optimalisatie",
    editorialreview: "Onze redactie zal uw advertentie beoordelen en voorstellen hoe deze kan worden verbeterd om de beste prestaties te garanderen.",
    tcp: "Transparant Bedrijfsprofiel",
    relevantmagazines: "Vacature plaatsing in ons relevante magazine",
    tcphint: "Een geweldige manier om meer te vertellen over uw bedrijfsverhaal, cultuur en teamleden. U kunt foto's en video's naar keuze opnemen. Klik op de link om profielen van onze Transparante Bedrijven te bekijken!",
    customdesign: "Aangepaste ontwerpopties",
    activeuntil: "actief totdat u de juiste kandidaat vindt en de vacature sluit",
    iframewith: "Iframe met uw originele ontwerp weergegeven in de functiebeschrijving",
    morejobsorstartup: "Wil je meerdere vacatures publiceren of ben je een startup?",
    planningmorejobs: "Meer vacatures in de planning?",
    discountsforstartups: "we bieden kortingen voor start-ups en non-profits aan",
    contactus: "Neem contact met ons op",
    companyname: "Bedrijfsnaam",
    yourname: "Uw naam",
    youremail: "Jouw email",
    phonenumber: "Telefoonnummer",
    password: "Wachtwoord",
    message: "Jouw bericht",
    jobsnumber: "Jobnummer",
    wearestartup: "Wij zijn een startup",
    consideredstartup: "U wordt beschouwd als een startup als uw bedrijf minder dan 5 jaar geleden is gestart en u minder dan 50 werknemers in dienst heeft",
    send: "Sturen",
    thankyou: "Bedankt voor uw aanvraag!",
    wewillcontact: "We komen snel bij u terug.",
    tailoredoffer: "Neem contact met ons op voor aanbiedingen op maat en speciale kortingen voor start-ups en non-profitorganisaties",
    visasponsorship: "Visumsponsoring",
    yes: "Ja ",
    no: "Nee ",
    ingermany: "in Duitsland",
    inswitzerland: "in Zwitserland",
    inromania: "in Roemenië",
    inuk: "in het Verenigd Koninkrijk",
    inusa: "in de Verenigde Staten",
    innetherlands: "in Nederland",
    onejob: "Een enkele vacature kost slechts " + AD_PRICE,
    daysactive: "Onbeperkte tijd",
    perjobad: "per vacature",
    onwebsite: "op onze website totdat je de juiste kandidaat hebt aangenomen",
    presence: "Gepromoot in de",
    newsletter: "vacaturenieuwsbrief met meer dan 3000 IT-professionals",
    broadcasted: "Uitgezonden in ",
    andour: "en op onze",
    partnerjobboards: "partnerjobboards",
    hiringorg: "inhurende organisatie",
    hiringorgaddress: "adres van de inlenende organisatie",
    salaryrange: "jaarlijkse salarisbereik",
    applybtn: "knop toepassen",
    jobalertbtn: "vacature-alert knop",
    developer: "Developer",
    developers: "Developers",
    engineer: "Engineer",
    engineers: "Engineers",
    designer: "Ontwerper",
    designers: "Ontwerpers",
    specialist: "Specialist",
    specialists: "Specialisten",
    blog: "Blog- en Carrièretips",
    perhour: "uur",
    perday: "dag",
  },
  ro: {
    All: "Toate",
    softwareengineer: "Inginer de software",
    softwaredeveloper: "Dezvoltator de software",
    jobs: "joburi",
    job: "job",
    geta: "Primeste",
    weekly: "saptamanal",
    emailwith: "un email cu cele mai noi",
    joboffers: "joburi",
    wepromise: "Va promitem ca va vom trimite doar buletinul saptamanal de locuri de munca.",
    yourname: "Numele tau",
    email: "E-mail",
    close: "Inchide",
    subscribe: "Abonati-va",
    bysendingyourdata: "Prin trimiterea datelor din formular, sunteti de acord cu prelucrarea acestora de catre site-ul DevJob.ro, conform",
    bysendingyourdataGDJ: "By sending your contact details you agree to data processing by the administrator: GermanTechJobs. The administrator processes data following the",
    privacypolicy: "Politicii de confidentialitate.",
    thankyousubscribing: "Multumesc pentru abonare.",
    confirmemail: "Va rugam sa confirmati newsletterul dvs. saptamanal facand clic pe linkul pe care l-ati primit.",
    Contract: "Freelance",
    Internship: "Stagiu",
    "Part-Time": "Jumatate de norma",
    "Full-Time": "Norma întreaga",
    remote: "Remote",
    hybrid: "Hybrid",
    office: "Biroul",
    anywhere: "Mondial",
    countryandeu: "În " + TRANSLATIONS.ro.country + " și UE",
    onlycountry: "Doar în " + TRANSLATIONS.ro.country,
    English: "Engleza",
    Italian: "Italiana",
    Romanian: "Romana",
    Product: "Produs",
    Services: "Agentie / Servicii",
    Startup: "Startup",
    pricing: "Listă de prețuri",
    pricingshort: "Prețuri",
    whatisincluded: "Ce este inclus în anunțul de angajare",
    whatisincludedbundle: "Ce este inclus în pachet",
    publicationduration: "Valabilitate anunț",
    publishjob: "Publica",
    bestvalue: "Cel mai popular",
    tryforfree: "Test gratuit",
    packagecontains5: "Acest pachet conține 5 anunțuri de angajare, iar acesta este prețul dvs. pe anunț.",
    packagecontains10: "Acest pachet conține 10 anunțuri de angajare, iar acesta este prețul dvs. pe anunț.",
    youcantryfreero: "Puteți încerca platforma noastră, oferim 1 anunta complet gratuit",
    youcantryfree: "Puteți încerca anunțul nostru de angajare cu 1 anunta de bază, complet gratuit",
    durationhint: "Varianta nelimitată presupune că jobul va fi activ până când găsiți candidatul potrivit și se ocupă poziția",
    unlimited: "Nelimitat",
    days: "de zile",
    refreshbump: "Refresh către top",
    refreshhint: "Anunțul va fi reactualizat și va apărea în primele rezultate ale listei beneficiind de vizibilitate sporită",
    once: "O dată",
    multiple: "Multiplu",
    numberoflocations: "Locații de locuri de muncă",
    locationshint: "Jobul tău va fi anunțat în mai multe orașe - perfect pentru companii cu mai multe locații de birou",
    numberofjobs: "Număr de anunțuri",
    numberofjobshint: "Câte anunțuri sunt incluse",
    upto5: "Până la 5",
    individualsupport: "Suport dedicat",
    supporthint: "Scrie-ne oricând și vom încerca să te ajutăm! :)",
    promoinapp: "Publicare suplimentară în aplicația noastră mobilă",
    placedinourapps: "Anunțurile dvs. de angajare vor fi publicate în aplicațiile noastre mobile oficiale (Android și iOS)",
    includednewsletter: "Newsletter",
    subscribers: "Inclus în newsletter-ul săptămânal cu peste " + NEWSLETTER_SUB_NUMBER + " de abonați ",
    socialmediapromo: "Social media",
    overfollowers: "Promovat pe canalele noastre social media (LinkedIn, Facebook, Twitter) - peste " + SOCIAL_FOLLOWERS + " de followers.",
    adreview: "Evaluare anunț & optimizare",
    editorialreview: "Echipa noastră editorială va evalua anunțul și va sugera cum poate fi îmbunătățit pentru cele mai bune performanțe.",
    tcp: "Companie transparentă",
    tcphint: "Listare premium pentru a atrage candidați de top. Profilul de companie poate include imagini si video. Click pentru cateva exemple",
    customdesign: "Design personalizat",
    activeuntil: "activ până când găsiți candidatul potrivit și închideți anunțul de angajare",
    iframewith: "Iframe cu designul original afișat în fișa postului",
    morejobsorstartup: "Doriți să publicați mai multe joburi sau sunteți un startup?",
    planningmorejobs: "Planificați mai multe posturi disponibile?",
    discountsforstartups: "oferim reduceri pentru start-up-uri și ONG-uri",
    largejobsorstartup: "Doriți să publicați un număr mai mare de joburi sau sunteți un start-up în dezvoltare?",
    contactus: "Contactați-ne",
    companyname: "Nume companie",
    yourname: "Nume reprezentant",
    youremail: "E-mail",
    phonenumber: "Număr de telefon",
    password: "Parola",
    message: "Mesajul dvs",
    jobsnumber: "Numărul locurilor de muncă",
    wearestartup: "Suntem un startup",
    consideredstartup: "Sunteți considerat un startup dacă compania dvs. a fost înființată cu mai puțin de 5 ani în urmă și aveți mai puțin de 50 de angajați",
    send: "Trimite",
    thankyou: "Vă mulțumim pentru întrebare!",
    wewillcontact: "Vă revenim în scurt timp.",
    tailoredoffer: "Contactează-ne pentru oferte personalizate și reduceri speciale pentru start-up-uri și non-profit",
    notconvinced: "Nu esti convins ca " + FULL_NAME + " este solutia potrivita pentru tine?",
    leaveyourquestion: "Trimite-ne intrebarea/mesajul tau si iti vom raspunde cu prioritate.",
    questionmessage: "Intrebare / mesaj",
    yes: "Da ",
    no: "Nu ",
    ingermany: "în Germania",
    inswitzerland: "in Elvetia",
    inromania: "În România",
    inuk: "în Regatul Unit",
    inusa: "in Statele Unite",
    innetherlands: "în Olanda",
    freeofcargeromania: "Publica gratuit locurile de munca in IT (dezvoltatori software, programatori, business analyst, etc).",
    hiringorg: "organizatie de angajare",
    hiringorgaddress: "adresa organizației de angajare",
    salaryrange: "intervalul salarial anual",
    applybtn: "butonul de aplicare",
    jobalertbtn: "butonul de alertă de locuri de muncă",
    perjobad: "pe anunt de angajare",
    developer: "Developer",
    developers: "Dezvoltatori",
    engineer: "Programator",
    engineers: "Programatori",
    designer: "Designer",
    designers: "Designeri",
    specialist: "Specialist",
    specialists: "Specialisti",
    blog: "Blog și Sfaturi de Carieră",
    perhour: "ora",
    perday: "zi",
  },
}

class LangRouter {
  constructor(locale) {
    this.locale = locale ? locale : BASE_LANG;
    if (this.locale.startsWith("/")) this.locale = this.locale.slice(1, 3)
    this.routes = routes;
  }
  route = (path) => {
    return this.routes[this.locale] && this.routes[this.locale][path]
  }
}
const routes = {
  en: {
    faq: "/frequently-asked-questions",
    about: "/about",
    usefullinks: "/useful-links",
    companies: "/companies",
    devtraining: "/developer-training",
  },
  de: {
    faq: "/haeufig-gestellte-fragen",
    about: "/ueber-uns",
    usefullinks: "/nuetzliche-links",
    companies: "/companies",
    devtraining: "/programmierkurs",
  },
  fr: {
    faq: "/foire-aux-questions",
    about: "/a-propos-de-nous",
    usefullinks: "/liens-utiles",
    companies: "/companies",
    devtraining: "/cours-de-programmation"
  },
  nl: {
    faq: "/veel-gestelde-vragen",
    about: "/over-ons",
    usefullinks: "/handige-links",
    companies: "/bedrijven",
    devtraining: "/developer-training",
  },
  ro: {
    faq: "/informatii-utile",
    about: "/despre-noi",
    usefullinks: "/link-uri-utile",
    companies: "/companies",
    devtraining: "/curs-de-programare"
  },
}

export {
  Translator,
  LangRouter,
  routes,
  commonTranslations,
  rootWithLocale,
}