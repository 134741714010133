import { MDBBtn, MDBCollapse, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink, MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu } from 'mdbreact';
import React from 'react';
import { withRouter, matchPath, Link } from 'react-router-dom';
import consts from '../consts';
import { Translator, LangRouter, commonTranslations } from '../translationService';
import { siteConfig } from '../siteConfig'
import { ALL, getDisplayedCity, getCapitalizedCategory, getDisplaySeoTitleCategory } from '../helperUtil'
const { SITE_NAME, LOGO_SRC, STATIC_ROOT, TRANSLATIONS, PAGE_ROOT, IN_COUNTRY } = siteConfig

const COUNTRIES = {
    SDJ: "inswitzerland",
    GDJ: "ingermany",
    NDJ: "innetherlands",
    RDJ: "inromania",
    UKJ: "inuk",
    USJ: "inusa",
}

const LOGO_WIDTH = {
    SDJ: "111px",
    GDJ: "108px",
    NDJ: "42px",
    RDJ: "127px",
    UKJ: "165px",
    USJ: "165px",
}

const translations = {
    de: {
        logoAltTitle: "IT & Softwareentwickler Jobs mit Gehalt",
        jobs: "Stellenangebote",
        jobzz: "Jobs",
        salaries: "Gehälter",
        salarystats: "Gehälter",
        companyinterviews: "Transparente Arbeitgeber",
        companyprofiletitle: "IT Unternehmen",
        developertraining: "Programmierkurs",
        developertrainingtitle: "Online Programmierkurs",
        howtofindjob: "Wie findet man Arbeit in der Schweiz",
        aboutus: "Über uns",
        postjob: "Job Publizieren / Einloggen",
        postfreejob: "Job Kostenlos Publizieren",
        jobalert: "Job Alert",
        jobsineurope: "Jobs in Europa",
        othercountries: "Andere Länder",
        community: "Community",
        techcommunities: "Tech-Communities ",
        hackathonsconferences: "Hackathons und Konferenzen ",
    },
    en: {
        logoAltTitle: "IT & Software developer jobs with salaries",
        jobs: "Jobs",
        jobzz: "Jobs",
        salaries: "Salaries",
        salarystats: "Salary stats",
        companyinterviews: "Transparent Companies",
        companyprofiletitle: "IT Companies",
        developertraining: "Coding Bootcamp",
        developertrainingtitle: "Online Coding Bootcamp",
        howtofindjob: "How to find a job in Switzerland",
        aboutus: "About Us",
        postjob: "Post A Job / Log In",
        postfreejob: "Post A Job For Free",
        jobalert: "Job Alert",
        jobsineurope: "Jobs in Europe",
        othercountries: "Other countries",
        community: "Community",
        techcommunities: "Tech Communities ",
        hackathonsconferences: "Hackathons and Conferences ",
        certification: "Certifications",
    },
    fr: {
        logoAltTitle: "Dev jobs - Développeurs",
        jobs: "emplois",
        jobzz: "Emplois",
        salaries: "Salaires",
        salarystats: "Salaires",
        companyinterviews: "Employeurs transparents",
        companyprofiletitle: "Entreprises informatiques",
        developertraining: "La formation Développeur",
        developertrainingtitle: "La formation Développeur",
        howtofindjob: "Comment trouver un emploi en Suisse",
        aboutus: "À propos",
        postjob: "Publier un job / Se Connecter",
        postfreejob: "Publiez Une Offre D'emploi Gratuitement",
        jobalert: "Alerte Emploi",
        jobsineurope: "Emplois en europe",
        othercountries: "Autres pays",
        community: "Communauté",
        techcommunities: "Communautés technologiques ",
        hackathonsconferences: "Hackathons et conférences ",
    },
    nl: {
        logoAltTitle: "Vacatures voor IT & Software Developer met salaris",
        jobs: "Vacatures",
        jobzz: "Vacatures",
        salaries: "Salarissen",
        salarystats: "Salarissen",
        companyinterviews: "Transparante bedrijven",
        companyprofiletitle: "IT-bedrijven",
        developertraining: "Coding Bootcamp",
        developertrainingtitle: "Online Coding Bootcamp",
        howtofindjob: "How to find a job in Switzerland",
        aboutus: "Over ons",
        postjob: "Plaats een vacature / Log in",
        postfreejob: "Post A Job For Free",
        jobalert: "Werk Alarm",
        jobsineurope: "Banen in europa",
        othercountries: "Andere landen",
        community: "Community",
        techcommunities: "Tech Communities ",
        hackathonsconferences: "Hackathons en conferenties ",
    },
    ro: {
        logoAltTitle: "Dezvoltator de software joburi",
        jobs: "Joburi",
        jobzz: "Joburi IT",
        salaries: "Salarii",
        salarystats: "Salarii",
        companyinterviews: "Companii transparente",
        companyprofiletitle: "Companii IT",
        developertraining: "Coding Bootcamp",
        developertrainingtitle: "Coding Bootcamp",
        howtofindjob: "How to find a job in Switzerland",
        aboutus: "Despre noi",
        postjob: "Posteaza un job / Conecteaza-te",
        postfreejob: "Postează Un Job Gratuit",
        jobalert: "Job Alert",
        jobsineurope: "Joburi in Europa",
        othercountries: "Alte tari",
        community: "Community",
        techcommunities: "Tech Communities ",
        hackathonsconferences: "Hackathon-uri și conferințe ",
        certification: "Certificari",
    },
}

class NavBarSection extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
            isNavbarWideEnough: false,
        };
    }

    onToggleClick = () => {
        if (this.props.isMobile || this.props.isTablet) {
            this.setState({
                collapse: !this.state.collapse,
            });
        }
    }

    render() {
        const locale = this.props.locale;
        const prefixedLocale = this.props.locale ? "/" + this.props.locale : ""
        const chosenLang = this.props.chosenLang
        const route = new LangRouter(locale).route
        const t = new Translator(locale, [translations, TRANSLATIONS, commonTranslations]).t
        const enTranslator = new Translator("en", [translations, TRANSLATIONS, commonTranslations]).t
        let matchedParams = matchPath(this.props.location.pathname, { path: this.props.base + "/jobs/:techCat/:cityCat" })
        if (SITE_NAME === "USJ") matchedParams = matchPath(this.props.location.pathname, { path: this.props.base + "/jobs/:techCat/:stateCat/:cityCat" })
        const techCategory = (matchedParams && matchedParams.params && matchedParams.params.techCat) || ALL;
        const cityCategory = (matchedParams && matchedParams.params && matchedParams.params.cityCat) || ALL;
        const stateCategory = (matchedParams && matchedParams.params && matchedParams.params.stateCat) || ALL;
        const displayedState = getCapitalizedCategory(stateCategory, "-")
        let logoAltTitle = t("logoAltTitle") + " " + t("incountry") + " " + t("country")
        if (SITE_NAME === "HNM") logoAltTitle = "Jobs in United States - HiringNearMe"
        const isMobile = (window.innerWidth <= consts.MOBILE_WIDTH || document.documentElement.clientWidth <= consts.MOBILE_WIDTH);
        const isSmallDesktopWidth = ((window.innerWidth <= 1400 && window.innerWidth > 1199) || (document.documentElement.clientWidth <= 1400 && document.documentElement.clientWidth > 1199));
        const titleLinkPage = ((techCategory && techCategory.toLowerCase() !== ALL) ? getDisplaySeoTitleCategory(techCategory, locale) : "IT & " + enTranslator("softwaredeveloper")) + " " + enTranslator("jobs") + " "
        const titleLinkPageTranslated = ((techCategory && techCategory.toLowerCase() !== ALL) ? getDisplaySeoTitleCategory(techCategory, locale) : "IT & " + t("softwaredeveloper")) + " " + t("jobzz") + " "
        const titleLinkPageRomania = "Joburi " + ((techCategory && techCategory.toLowerCase() !== ALL) ? getDisplaySeoTitleCategory(techCategory, locale) : "IT & Software Developer")
        const isRomanianLang = SITE_NAME === "RDJ" && locale !== "en";
        const isGermanLang = (SITE_NAME === "GDJ" && locale !== "en") || (SITE_NAME === "SDJ" && locale === "de")
        const inCountryLocalised = t("incountry") + " " + t("country")
        return (
            <header>
                <MDBNavbar dark expand="xl" id="main-navbar" scrolling className={"py-0 px-1 navbar-color navbar-font " + (isMobile ? "" : "fixed-top")}>
                    <MDBNavbarBrand className={"py-0 pr-0 mr-0"}>
                        {SITE_NAME === "HNM" ?
                            <a href={PAGE_ROOT} className="p-0">
                                <img src={LOGO_SRC} alt={logoAltTitle} title={logoAltTitle} aria-label={logoAltTitle}
                                    className={"portal-logo d-inline-block align-top "} />
                            </a>
                            :
                            <a href={PAGE_ROOT + prefixedLocale} className="p-0">
                                <img src={LOGO_SRC} alt={logoAltTitle} title={logoAltTitle} aria-label={logoAltTitle}
                                    className={"portal-logo d-inline-block align-top " + (["UKJ", "USJ", "NDJ"].includes(SITE_NAME) && "py-1")} style={{ width: LOGO_WIDTH[SITE_NAME] }} />
                            </a>

                        }
                    </MDBNavbarBrand>
                    {!this.state.isNavbarWideEnough &&
                        <div>
                            <span className="only-mobile">
                                {SITE_NAME === "RDJ" && <img width="30" height="30" onClick={() => this.props.changeLang("ro")} className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "ro" && "selected-border")} src={STATIC_ROOT + "/utilities/romanian-flag.png"} alt="Romanian language option" />}
                                {SITE_NAME === "NDJ" && <img width="30" height="30" onClick={() => this.props.changeLang("nl")} className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "nl" && "selected-border")} src={STATIC_ROOT + "/utilities/dutch-lang-flag.png"} alt="Dutch language option" />}
                                {SITE_NAME !== "HNM" && SITE_NAME !== "UKJ" && SITE_NAME !== "USJ" && <img width="30" height="30" onClick={() => this.props.changeLang("en")} className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "en" && "selected-border")} src={STATIC_ROOT + "/utilities/english-lang-flag.png"} alt="English language option" />}
                                {(SITE_NAME === "SDJ" || SITE_NAME === "GDJ") && <img width="30" height="30" onClick={() => this.props.changeLang("de")} className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "de" && "selected-border")} src={STATIC_ROOT + "/utilities/german-lang-flag.png"} alt="German language option" />}
                                {SITE_NAME === "SDJ" && <img width="30" height="30" onClick={() => this.props.changeLang("fr")} className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "fr" && "selected-border")} src={STATIC_ROOT + "/utilities/french-lang-flag.png"} alt="French language option" />}
                            </span>
                            <MDBNavbarToggler aria-label="menu toggle" onClick={this.onToggleClick} />
                        </div>}
                    <MDBCollapse isOpen={this.state.collapse} navbar>
                        <MDBNavbarNav left>
                            {SITE_NAME === "NDJ" &&
                                <MDBNavItem className="mr-2 mx-1">
                                    <MDBNavLink to={prefixedLocale} >
                                        DevITjobs.nl
                                    </MDBNavLink>
                                </MDBNavItem>
                            }
                            {SITE_NAME !== "HNM" &&
                                <MDBNavItem className="mx-1">
                                    <MDBNavLink title={logoAltTitle} aria-label={logoAltTitle} to={prefixedLocale} onClick={this.onToggleClick}>{t("jobzz")}</MDBNavLink>
                                </MDBNavItem>
                            }
                            {/* Previous approach because it wasn't fitting */}
                            {/* {(window.innerWidth < 1200 || window.innerWidth > 1440) &&
                                <MDBNavItem className="mx-1">
                                    <MDBNavLink title={t("companyprofiletitle")} to={prefixedLocale + route("companies")} onClick={this.onToggleClick}>{t("companyinterviews")}</MDBNavLink>
                                </MDBNavItem>} */}
                            <MDBNavItem className="mx-1">
                                <MDBNavLink title={t("companyprofiletitle")} aria-label={t("companyprofiletitle")} to={prefixedLocale + route("companies")} onClick={this.onToggleClick}>{t("companyinterviews")}</MDBNavLink>
                            </MDBNavItem>
                            <React.Fragment>
                                <MDBNavItem className="mx-1">
                                    <MDBNavLink title={"Talent Directory"} aria-label={"Talent Directory"} to={prefixedLocale + "/talent-directory?source=menu"} onClick={this.onToggleClick}>{"Talent Directory"}</MDBNavLink>
                                </MDBNavItem>
                            </React.Fragment>
                            {SITE_NAME !== "USJ" &&
                                <MDBNavItem className='ml-1 ml-xl-0'>
                                    <MDBDropdown size="sm" show="yes" className="m-0 p-0 show">
                                        <MDBDropdownToggle nav caret={false} color="primary">{t("salarystats")} <i className="icon-chevron-down mx-1" aria-hidden="true" /></MDBDropdownToggle>
                                        <MDBDropdownMenu basic show="yes">
                                            <MDBDropdownItem><a title={getDisplaySeoTitleCategory(techCategory, locale) + " " + t("salaries") + " " + inCountryLocalised} href={techCategory === ALL ? prefixedLocale + "/salaries" : prefixedLocale + "/salaries/" + techCategory + "/all/all"} target="_blank">{isRomanianLang ? t("salaries") : ""} {getDisplaySeoTitleCategory(techCategory, locale) + (isRomanianLang ? "" : " " + t("salaries")) + " " + inCountryLocalised}</a></MDBDropdownItem>
                                            {cityCategory !== ALL && <MDBDropdownItem><a title={getSalariesTitle({ tech: getDisplaySeoTitleCategory(techCategory, locale), city: getDisplayedCity(cityCategory, locale), t: t })} href={prefixedLocale + "/salaries/" + techCategory + "/" + cityCategory + "/all"} target="_blank">{isRomanianLang ? t("salaries") : ""} {getSalariesTitle({ tech: getDisplaySeoTitleCategory(techCategory, locale), city: getDisplayedCity(cityCategory, locale), t: t })}</a></MDBDropdownItem>}
                                            {techCategory !== ALL && <MDBDropdownItem><a title={getDisplaySeoTitleCategory(ALL, locale) + " " + t("salaries") + " " + inCountryLocalised} href={prefixedLocale + "/salaries"} target="_blank">{isRomanianLang ? t("salaries") : ""} {getDisplaySeoTitleCategory(ALL, locale) + (isRomanianLang ? "" : " " + t("salaries")) + " " + inCountryLocalised}</a></MDBDropdownItem>}
                                            {techCategory !== ALL && cityCategory !== ALL && <MDBDropdownItem><a title={getSalariesTitle({ tech: getDisplaySeoTitleCategory(ALL, locale), city: getDisplayedCity(cityCategory, locale), t: t })} href={prefixedLocale + "/salaries/" + "all" + "/" + cityCategory + "/all"} target="_blank">{isRomanianLang ? t("salaries") : ""} {getSalariesTitle({ tech: getDisplaySeoTitleCategory(ALL, locale), city: getDisplayedCity(cityCategory, locale), t: t })}</a></MDBDropdownItem>}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            }
                            {SITE_NAME === "USJ" &&
                                <MDBNavItem className='ml-1 ml-xl-0'>
                                    <MDBDropdown size="sm" show="yes" className="m-0 p-0 show">
                                        <MDBDropdownToggle nav caret={false} color="primary">Salaries <i className="icon-chevron-down mx-1" aria-hidden="true" /></MDBDropdownToggle>
                                        <MDBDropdownMenu basic show="yes">
                                            <MDBDropdownItem><a title={getDisplaySeoTitleCategory(techCategory, locale) + " salaries " + IN_COUNTRY} href={techCategory === ALL ? prefixedLocale + "/salaries" : prefixedLocale + "/salaries/" + techCategory + "/all/all/all"} target="_blank">{getDisplaySeoTitleCategory(techCategory, locale) + " salaries " + IN_COUNTRY}</a></MDBDropdownItem>
                                            {stateCategory !== ALL && <MDBDropdownItem><a title={getSalariesTitle({ tech: getDisplaySeoTitleCategory(techCategory, locale), state: displayedState })} href={prefixedLocale + "/salaries/" + techCategory + "/" + stateCategory + "/all/all"} target="_blank">{getSalariesTitle({ tech: getDisplaySeoTitleCategory(techCategory, locale), state: displayedState })}</a></MDBDropdownItem>}
                                            {techCategory !== ALL && <MDBDropdownItem><a title={getDisplaySeoTitleCategory(ALL, locale) + " salaries " + IN_COUNTRY} href={prefixedLocale + "/salaries/all/all/all/all"} target="_blank">{getDisplaySeoTitleCategory(ALL, locale) + " salaries " + IN_COUNTRY}</a></MDBDropdownItem>}
                                            {techCategory !== ALL && stateCategory !== ALL && <MDBDropdownItem><a title={getSalariesTitle({ tech: getDisplaySeoTitleCategory(ALL, locale), state: displayedState })} href={prefixedLocale + "/salaries/all/" + stateCategory + "/all/all"} target="_blank">{getSalariesTitle({ tech: getDisplaySeoTitleCategory(ALL, locale), state: displayedState })}</a></MDBDropdownItem>}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            }
                            <MDBNavItem className='ml-1 ml-xl-0'>
                                <MDBDropdown size="sm" show="yes" className="m-0 p-0 show">
                                    <MDBDropdownToggle nav caret={false} color="primary">{t("community")} <i className="icon-chevron-down mx-1" aria-hidden="true" /></MDBDropdownToggle>
                                    <MDBDropdownMenu basic show="yes">
                                        {<Link title={t("techcommunities") + t(COUNTRIES[SITE_NAME])} to={"/community"} className="p-0"><MDBDropdownItem>{t("techcommunities")}</MDBDropdownItem></Link>}
                                        {<Link title={t("hackathonsconferences") + t(COUNTRIES[SITE_NAME])} to={"/events"} className="p-0"><MDBDropdownItem>{t("hackathonsconferences")}</MDBDropdownItem></Link>}
                                        <Link title={t("blog")} to={prefixedLocale + "/blog"} onClick={this.onToggleClick} className="p-0"><MDBDropdownItem>{t("blog")}</MDBDropdownItem></Link>
                                        {/* {window.innerWidth > 1200 && window.innerWidth < 1400 && <MDBDropdownItem><Link title={t("developertrainingtitle")} to={prefixedLocale + route("devtraining")} onClick={this.onToggleClick}>{t("developertraining")}</Link></MDBDropdownItem>} */}
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>
                            {SITE_NAME === "RDJ" && <React.Fragment>
                                <MDBNavItem className="mx-1">
                                    <MDBNavLink title={"iSQI Certification"} aria-label={"iSQI Certification"} to={prefixedLocale + "/isqi-certification"} onClick={this.onToggleClick}>{t("certification")}</MDBNavLink>
                                </MDBNavItem>
                            </React.Fragment>}
                            {/* {SITE_NAME === "GDJ" &&
                                <React.Fragment>
                                    <MDBNavItem className="mx-1">
                                        <MDBNavLink to={prefixedLocale + "/react-course"} onClick={this.onToggleClick}>React Course</MDBNavLink>
                                    </MDBNavItem>
                                </React.Fragment>
                            } */}
                        </MDBNavbarNav>
                        <MDBNavbarNav right>
                            <MDBNavItem className="mx-2">
                                <MDBNavLink title={"Favourite jobs"} className="py-1 pl-xl-0 pr-0" to={prefixedLocale + "/my-favourite-jobs"} onClick={this.onToggleClick}>{isMobile ? "Favourite jobs" : ""}<i className='px-1 icon-heart-o white-text font-size-bigger-everywhere font-weight-bold' /><span className='mr-0 favourite-counter'>{this.props.favouriteJobsUrls.length || ''}</span></MDBNavLink>
                            </MDBNavItem>
                            {SITE_NAME !== "HNM" &&
                                <MDBNavItem className='ml-1 ml-xl-0'>
                                    <MDBDropdown size="sm" show="yes" className="m-0 p-0 show">
                                        <MDBDropdownToggle nav caret={false} color="primary">{t("othercountries")} <i className="icon-chevron-down mx-1" aria-hidden="true" /></MDBDropdownToggle>
                                        <MDBDropdownMenu basic show="yes">
                                            {SITE_NAME !== "GDJ" && !isGermanLang && <MDBDropdownItem><a title={titleLinkPage + enTranslator("ingermany")} href={techCategory !== ALL ? "https://germantechjobs.de/en/jobs/" + techCategory + "/" + ALL : "https://germantechjobs.de/en"} target="_blank"><CountryFlag lang={"german"} /> <span className="align-middle">{titleLinkPage + enTranslator("ingermany")}</span></a></MDBDropdownItem>}
                                            {SITE_NAME !== "GDJ" && isGermanLang && <MDBDropdownItem><a title={titleLinkPageTranslated + t("ingermany")} href={techCategory !== ALL ? "https://germantechjobs.de/jobs/" + techCategory + "/" + ALL : "https://germantechjobs.de"} target="_blank"><CountryFlag lang={"german"} /> <span className="align-middle">{titleLinkPageTranslated + t("ingermany")} </span></a></MDBDropdownItem>}
                                            {SITE_NAME !== "NDJ" && <MDBDropdownItem><a title={titleLinkPage + enTranslator("innetherlands")} href={techCategory !== ALL ? "https://devitjobs.nl/en/jobs/" + techCategory + "/" + ALL : "https://devitjobs.nl/en"} target="_blank"><CountryFlag lang={"dutch"} /> <span className="align-middle">{titleLinkPage + enTranslator("innetherlands")} </span></a></MDBDropdownItem>}
                                            {SITE_NAME !== "RDJ" && <MDBDropdownItem><a title={titleLinkPageRomania + " in Romania"} href={techCategory !== ALL ? "https://devjob.ro/jobs/" + techCategory + "/" + ALL : "https://devjob.ro"} target="_blank"><CountryFlag lang={"romanian"} /> <span className="align-middle">{titleLinkPageRomania + " in Romania"} </span></a></MDBDropdownItem>}
                                            {SITE_NAME !== "SDJ" && !isGermanLang && <MDBDropdownItem><a title={titleLinkPage + enTranslator("inswitzerland")} href={techCategory !== ALL ? "https://swissdevjobs.ch/jobs/" + techCategory + "/" + ALL : "https://swissdevjobs.ch"} target="_blank"><CountryFlag lang={"swiss"} /> <span className="align-middle">{titleLinkPage + enTranslator("inswitzerland")} </span></a></MDBDropdownItem>}
                                            {SITE_NAME !== "SDJ" && isGermanLang && <MDBDropdownItem><a title={titleLinkPageTranslated + t("inswitzerland")} href={techCategory !== ALL ? "https://swissdevjobs.ch/de/jobs/" + techCategory + "/" + ALL : "https://swissdevjobs.ch/de"} target="_blank"><CountryFlag lang={"swiss"} /> <span className="align-middle">{titleLinkPageTranslated + t("inswitzerland")}</span> </a></MDBDropdownItem>}
                                            {SITE_NAME !== "UKJ" && <MDBDropdownItem><a title={titleLinkPage + enTranslator("inuk")} href={techCategory !== ALL ? "https://devitjobs.uk/jobs/" + techCategory + "/" + ALL : "https://devitjobs.uk"} target="_blank"><CountryFlag lang={"uk"} /> <span className="align-middle">{titleLinkPage + enTranslator("inuk")}</span> </a></MDBDropdownItem>}
                                            {SITE_NAME !== "USJ" && <MDBDropdownItem><a title={titleLinkPage + enTranslator("inusa")} href={techCategory !== ALL ? "https://devitjobs.com/jobs/" + techCategory + "/" + ALL + "/" + ALL : "https://devitjobs.com"} target="_blank"> <CountryFlag lang={"us"} /> <span className="align-middle">{titleLinkPage + enTranslator("inusa")}</span></a></MDBDropdownItem>}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                                // <MDBNavItem className="mx-1">
                                //     <MDBNavLink title={titleLinkPage} to={prefixedLocale + "/jobportals/" + techCategory} onClick={this.onToggleClick}>{titleLinkPage}</MDBNavLink>
                                // </MDBNavItem>
                            }
                            {/* {(SITE_NAME === "GDJ") &&
                                <React.Fragment>
                                    <MDBNavItem className="mx-1">
                                        <MDBNavLink title={"Honeypot.io"} to={prefixedLocale + "/honeypot"} onClick={this.onToggleClick}>Honeypot.io</MDBNavLink>
                                    </MDBNavItem>
                                </React.Fragment>
                            } */}
                            {/* {["GDJ", "SDJ"].includes(SITE_NAME) && (window.innerWidth < 1200 || window.innerWidth > 1400) &&
                                <React.Fragment>
                                    <MDBNavItem className="mx-1">
                                        <MDBNavLink title={t("developertrainingtitle")} to={prefixedLocale + route("devtraining")} onClick={this.onToggleClick}>{t("developertraining")}</MDBNavLink>
                                    </MDBNavItem>
                                </React.Fragment>
                            } */}
                            {!isSmallDesktopWidth &&
                                <MDBNavItem className="mx-1">
                                    {/*eslint-disable-next-line */}
                                    <MDBNavLink title="Newsletter" to={prefixedLocale + "/subscribe-newsletter?source=menu"} onClick={this.onToggleClick}>{t("jobalert")} <span className="icon-bell orange-text align-middle" /></MDBNavLink>
                                </MDBNavItem>}
                            {/* {["GDJ", "SDJ", "RDJ"].includes(SITE_NAME) && <MDBNavItem className="mx-1">
                                <MDBNavLink title={"Blog"} to={prefixedLocale + "/blog"} onClick={this.onToggleClick}>Blog</MDBNavLink>
                            </MDBNavItem>} */}
                            {SITE_NAME !== "HNM" &&
                                <MDBNavItem className="mx-1">
                                    <MDBNavLink title="FAQ" to={prefixedLocale + route("faq")} onClick={this.onToggleClick}>FAQ</MDBNavLink>
                                </MDBNavItem>}
                            <MDBNavItem className="mx-1">
                                <MDBNavLink title={t("aboutus")} to={prefixedLocale + route("about")} onClick={this.onToggleClick}>{t("aboutus")}</MDBNavLink>
                            </MDBNavItem>
                        </MDBNavbarNav>
                        {/*Need to use the ul because NavBarNav auto adds left margin*/}
                        {SITE_NAME !== "HNM" && <ul className="navbar-nav nav-flex-icons ml-0">
                            <MDBNavLink to={prefixedLocale + "/contact"} className="pt-0 px-0 pb-2 pb-lg-0" onClick={this.onToggleClick}>
                                <MDBBtn title={t("postjob")} color="orange" className="text-contrast-accent bigger-btn px-2 text-larger my-0">{t("postjob")}</MDBBtn>
                            </MDBNavLink>
                        </ul>}
                        <div className="only-desktop">
                            {SITE_NAME === "RDJ" && <img width="30" height="30" onClick={() => this.props.changeLang("ro")} alt="Romanian language option" className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "ro" && "selected-border")} src={STATIC_ROOT + "/utilities/romanian-flag.png"} />}
                            {SITE_NAME === "NDJ" && <img width="30" height="30" onClick={() => this.props.changeLang("nl")} alt="Dutch language option" className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "nl" && "selected-border")} src={STATIC_ROOT + "/utilities/dutch-lang-flag.png"} />}
                            {SITE_NAME !== "HNM" && SITE_NAME !== "UKJ" && SITE_NAME !== "USJ" && <img width="30" height="30" onClick={() => this.props.changeLang("en")} alt="English language option" className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "en" && "selected-border")} src={STATIC_ROOT + "/utilities/english-lang-flag.png"} />}
                            {(SITE_NAME === "SDJ" || SITE_NAME === "GDJ") && <img width="30" height="30" onClick={() => this.props.changeLang("de")} alt="German language option" className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "de" && "selected-border")} src={STATIC_ROOT + "/utilities/german-lang-flag.png"} />}
                            {SITE_NAME === "SDJ" && <img width="30" height="30" onClick={() => this.props.changeLang("fr")} alt="French language option" className={"img-fluid lang-picture mr-2 pointer-cursor " + (chosenLang === "fr" && "selected-border")} src={STATIC_ROOT + "/utilities/french-lang-flag.png"} />}
                        </div>
                    </MDBCollapse>
                </MDBNavbar>
            </header >
        );
    }
}

export default withRouter(NavBarSection);


const CountryFlag = ({ lang }) => {
    return (
        <img width="20" height="20" className={"img-fluid lang-picture mr-2 pointer-cursor std-rounded "} src={STATIC_ROOT + "/utilities/" + lang + "-lang-flag.png"} alt={lang + " language option"} />
    )
}

const getSalariesTitle = ({ tech, city, state, t }) => {
    if (state) {
        if (state.toLowerCase().includes("remote")) {
            return "Remote " + tech + " salaries in the US"
        } else {
            return tech + " salaries in " + state
        }
    } else if (city) {
        if (city.toLowerCase().includes("remote")) {
            return "Remote " + tech + " " + t("salaries") + " " + t("incountry") + " " + t("country")
        } else {
            return tech + " " + t("salaries") + " " + t("incity") + " " + city
        }
    }
}