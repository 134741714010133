import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { MDBNotification } from 'mdbreact';
import Cookies from 'universal-cookie';
import Loadable from 'react-loadable';
import LoadingComponent from './components/LoadingComponent';
import NavBarSection from './components/NavBarSection';
import NotFoundPage from './components/pages/NotFoundPage';
import NewsletterSubscribeBannerDesktop from './components/NewsletterSubscribeBannerDesktop';
import PromoBannerSurvey2024 from './components/PromoBannerSurvey2024';
import CookieBanner from './components/CookieBanner';
import consts from "./consts";
import NewsletterSubscribeBannerMobile from './components/NewsletterSubscribeBannerMobile';
import { siteConfig } from './siteConfig'
import { addToFavouritesAndReturnNew, ALL, detectIsSafari } from './helperUtil'
import { routes } from './translationService';
import './css/standard-theme.css'

const { LOCALE_BASE, BASE_LANG, SUPPORTED_LANGS, TAWK_ID, WONDERPUSH_PUBLIC_KEY, SITE_NAME, FULL_NAME, TWTTR_PXL_ID, FB_PXL_ID } = siteConfig
const TIMEOUT = 8000
const PAGES_PATH = "./components/pages/"
const AboutPage = Loadable({ loader: () => import(PAGES_PATH + "AboutPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const ContactPage = Loadable({ loader: () => import(PAGES_PATH + "ContactPage"), loading: LoadingComponent, timeOut: TIMEOUT });
// const PrototypeContactPage = Loadable({ loader: () => import(PAGES_PATH + "PrototypeContactPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const PrivacyPage = Loadable({ loader: () => import(PAGES_PATH + "PrivacyPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const CookiePage = Loadable({ loader: () => import(PAGES_PATH + "CookiePage"), loading: LoadingComponent, timeOut: TIMEOUT });
const BlogPage = Loadable({ loader: () => import(PAGES_PATH + "BlogPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const BlogPostPage = Loadable({ loader: () => import(PAGES_PATH + "BlogPostPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const CompanyPage = Loadable({ loader: () => import(PAGES_PATH + "CompanyPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const LiteCompanyPage = Loadable({ loader: () => import(PAGES_PATH + "LiteCompanyPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const CompaniesPage = Loadable({ loader: () => import(PAGES_PATH + "CompaniesPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const SurveyInterviewPage = Loadable({ loader: () => import(PAGES_PATH + "SurveyInterviewPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const LinkListPage = Loadable({ loader: () => import(PAGES_PATH + "LinkListPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const SalaryStatsPage = Loadable({ loader: () => import(PAGES_PATH + "SalaryStatsPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const CommunityPage = Loadable({ loader: () => import(PAGES_PATH + "CommunityPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const HubPage = Loadable({ loader: () => import(PAGES_PATH + "HubPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const ResourcesPage = Loadable({ loader: () => import(PAGES_PATH + "ResourcesPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const NewsletterSubscribePage = Loadable({ loader: () => import(PAGES_PATH + "NewsletterSubscribePage"), loading: LoadingComponent, timeOut: TIMEOUT });
const FaqPage = Loadable({ loader: () => import(PAGES_PATH + "FaqPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const PromoPage = Loadable({ loader: () => import(PAGES_PATH + "PromoPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const HoneyPromoPage = Loadable({ loader: () => import(PAGES_PATH + "HoneyPromoPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const IsqiPromoPage = Loadable({ loader: () => import(PAGES_PATH + "IsqiPromoPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const ReactCoursePage = Loadable({ loader: () => import(PAGES_PATH + "ReactCoursePage"), loading: LoadingComponent, timeOut: TIMEOUT });
const OpenStatsPage = Loadable({ loader: () => import(PAGES_PATH + "OpenStatsPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const InfographicPage = Loadable({ loader: () => import(PAGES_PATH + "InfographicPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const HomePageWrapperPage = Loadable({ loader: () => import(PAGES_PATH + "HomePageWrapperPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const CalendarPage = Loadable({ loader: () => import(PAGES_PATH + "CalendarPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const PricingPage = Loadable({ loader: () => import(PAGES_PATH + "PricingPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const TalentLandingPage = Loadable({ loader: () => import(PAGES_PATH + "TalentLandingPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const FavouriteJobsPage = Loadable({ loader: () => import(PAGES_PATH + "FavouriteJobsPage"), loading: LoadingComponent, timeOut: TIMEOUT });
const ReportDownloadPage2022 = Loadable({ loader: () => import(PAGES_PATH + "ReportDownloadPage2022"), loading: LoadingComponent, timeOut: TIMEOUT });
const ReportDownloadPage2023 = Loadable({ loader: () => import(PAGES_PATH + "ReportDownloadPage2023"), loading: LoadingComponent, timeOut: TIMEOUT });

const base = LOCALE_BASE;
const cookies = new Cookies();
const FAVOURITE_JOB_NOTIFICATION_TIME = 4000
const userAgent = navigator.userAgent || ""
const isPrerender = userAgent.includes("Prerender")
const isGoogle = userAgent.toLowerCase().includes("google")

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNewsletterBannerClosed: cookies.get("isNewsletterBannerClosed") || cookies.get("hasSubscribedNewsletter"),
      isPromoBannerSurvey2024Closed: cookies.get("isPromoBannerSurvey2024Closed"),
      // isTop100SwissStartupsBannerClosed: cookies.get("isTop100SwissStartupsBannerClosed"),
      isCookieBannerClosed: cookies.get("isCookieBannerClosed"),
      isMobileBannerClosed: cookies.get("isMobileBannerClosed"),
      optedOutFromCookies: cookies.get("optedOutFromCookies"),
      timeOfFirstVisit: cookies.get("timeOfFirstVisit"),
      chosenLang: this.getUserLang(),
      favouriteJobsUrls: cookies.get("favouriteJobs") || [],
      showNotification: false,
    };
  }

  getUserLang = () => {
    const cookieChosenLang = cookies.get("chosenLang")
    let userLang = BASE_LANG
    for (const langLocale of SUPPORTED_LANGS) {
      if (window.location.pathname === "/" + langLocale || window.location.pathname.startsWith("/" + langLocale + "/")) {
        userLang = langLocale
      }
    }
    if (cookieChosenLang) {
      userLang = cookieChosenLang
    }
    return userLang
  }

  componentDidMount() {
    const placeholder = document.querySelector('#html-placeholder');
    if (placeholder) {
      placeholder.parentNode.removeChild(placeholder);
    }

    if (this.state.isCookieBannerClosed && !isGoogle && !isPrerender) {
      // this.launchTwitterPixel()
      this.launchFbPixel()
      // this.launchBingAdsTag()
      setTimeout(() => {
        this.launchTawk();
      }, 15000);
      setTimeout(() => {
        this.launchWonderPush();
      }, 8000);
      let timeOfFirstVisit = cookies.get("timeOfFirstVisit")
      if (!timeOfFirstVisit) {
        timeOfFirstVisit = new Date()
        cookies.set("timeOfFirstVisit", timeOfFirstVisit.toISOString(), { path: '/', expires: cookieLifetime() })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }
  }


  // launchTwitterPixel() {
  //   if (TWTTR_PXL_ID) {
  //     !function (e, t, n, s, u, a) {
  //       e.twq || (s = e.twq = function () {
  //         s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
  //       }, s.version = '1.1', s.queue = [], u = t.createElement(n), u.async = !0, u.src = 'https://static.ads-twitter.com/uwt.js',
  //         a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a))
  //     }(window, document, 'script');
  //     twq('config', TWTTR_PXL_ID);
  //   }
  // }

  launchFbPixel() {
    if (FB_PXL_ID && SITE_NAME === "RDJ") {
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', FB_PXL_ID);
      fbq('track', 'PageView');
    }
  }

  // launchBingAdsTag() {
  //   if (SITE_NAME === "GDJ") {
  //     (function (w, d, t, r, u) { var f, n, i; w[u] = w[u] || [], f = function () { var o = { ti: "211027789", enableAutoSpaTracking: true }; o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad") }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () { var s = this.readyState; s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null) }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i) })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
  //   }
  // }

  launchWonderPush = () => {
    if (detectIsSafari() === false) {
      (function () {
        var s = document.createElement('script');
        s.src = "https://cdn.by.wonderpush.com/sdk/1.1/wonderpush-loader.min.js";
        document.head.appendChild(s);
      })();

      window.WonderPush = window.WonderPush || [];
      window.WonderPush.push(["init", {
        webKey: WONDERPUSH_PUBLIC_KEY,
      }]);
    }
  }

  launchTawk = async () => {
    /* eslint-disable-next-line */
    window.Tawk_API = window.Tawk_API || {}, window.Tawk_LoadStart = new Date();
    (function () {
      const s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/' + TAWK_ID;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  closeNewsletterSubscribe = () => {
    cookies.set("isNewsletterBannerClosed", true, { path: '/', expires: cookieLifetime() })
    this.setState({ isNewsletterBannerClosed: true })
  }

  closePromoBannerSurvey2024 = () => {
    cookies.set("isPromoBannerSurvey2024Closed", true, { path: '/', expires: cookieLifetime() })
    this.setState({ isPromoBannerSurvey2024Closed: true })
  }

  // closeTop100SwissStartupsBanner = () => {
  //   cookies.set("isTop100SwissStartupsBannerClosed", true, { path: '/', expires: cookieLifetime() })
  //   this.setState({ isTop100SwissStartupsBannerClosed: true })
  // }


  closeMobileBanner = () => {
    cookies.set("isMobileBannerClosed", true, { path: '/', expires: cookieLifetime() })
    this.setState({ isMobileBannerClosed: true })
  }

  optOutFromCookies = () => {
    cookies.set("optedOutFromCookies", true, { path: '/', expires: cookieLifetime() })
    cookies.remove("isCookieBannerClosed", { path: '/' })
    this.setState({ optedOutFromCookies: true, isCookieBannerClosed: false })
  }

  acceptCookiesAndCloseCookieBanner = () => {
    cookies.set("isCookieBannerClosed", true, { path: '/', expires: cookieLifetime() })
    cookies.remove("optedOutFromCookies", { path: '/' })
    // window.dataLayer = window.dataLayer || [];
    // window['ga-disable-' + process.env.REACT_APP_GA_ID] = false;
    // function gtag() { dataLayer.push(arguments); }
    // gtag('consent', 'update', {
    //   'analytics_storage': 'granted'
    // });
    let timeOfFirstVisit = cookies.get("timeOfFirstVisit")
    if (!timeOfFirstVisit) {
      timeOfFirstVisit = new Date()
      cookies.set("timeOfFirstVisit", timeOfFirstVisit.toISOString(), { path: '/', expires: cookieLifetime() })
    }
    setTimeout(() => {
      this.launchTawk();
    }, 15000);
    setTimeout(() => {
      this.launchWonderPush();
    }, 8000);
    // this.launchTwitterPixel()
    this.launchFbPixel()
    // this.launchBingAdsTag()
    this.setState({ isCookieBannerClosed: true, optedOutFromCookies: false, timeOfFirstVisit: timeOfFirstVisit })
  }

  getLocale = (match) => {
    return match.params.locale ? match.params.locale : ""
  }

  shouldSwitchToWhichLang = locale => {
    const cookiesLang = cookies.get("chosenLang")
    if (cookiesLang === BASE_LANG && locale === "") {
      return false
    }
    if (cookiesLang && SUPPORTED_LANGS.includes(cookiesLang) && cookiesLang !== locale) {
      this.setState({ chosenLang: cookiesLang })
      return cookiesLang
    }
    return false
  }

  changeLang = (lang) => {
    if (!lang) { return };
    cookies.set("chosenLang", lang, { path: '/', expires: cookieLifetime() })
    this.setState({ chosenLang: lang })
  }

  parseTechCategory = (techCat) => {
    if (["ui-ux-designer", "data-science", "dev-ops", "machine-learning"].includes(techCat.toLowerCase())) {
      return techCat
    } else {
      return techCat.replace(/-/g, " ")
    }
  }

  onFavouriteJobClick = (job) => {
    const newFavourites = addToFavouritesAndReturnNew(job, this.state.favouriteJobsUrls, this.triggerNotification)
    this.setState({ favouriteJobsUrls: newFavourites })
  }

  triggerNotification = () => {
    this.setState({ showNotification: true })
    setTimeout(() => {
      this.setState({ showNotification: false })
    }, FAVOURITE_JOB_NOTIFICATION_TIME);
  }

  getCurrentLocaleAndRouteKey(currentRoute) {
    for (const locale in routes) {
      const localeRoutes = routes[locale];
      for (const key in localeRoutes) {
        if (currentRoute === localeRoutes[key]) {
          return key;
        }
      }
    }
    return null;
  }

  getRouteForLocale(routeKey, locale) {
    if (locale === "") {
      locale = BASE_LANG
    }
    return routes[locale] && routes[locale][routeKey];
  }

  getLocaleRoute(currentRoute, locale) {
    if (currentRoute && currentRoute.startsWith("/" + locale + "/")) {
      currentRoute = currentRoute.slice(3)
    }
    const routeKey = this.getCurrentLocaleAndRouteKey(currentRoute);
    if (routeKey) {
      const targetRoute = this.getRouteForLocale(routeKey, locale);
      if (targetRoute && targetRoute !== currentRoute) {
        this.setState({ randomVar: targetRoute }) // we need to do it so that Redirect works, because of this bug: https://github.com/reach/router/issues/504
        return (locale === "" ? "" : "/" + locale) + targetRoute;
      }
      return null
    }
    return null
  }

  render() {
    const pathName = window.location && window.location.pathname
    const curPath = pathName + window.location.search //subpath + query params
    const isMobile = (window.innerWidth <= consts.MOBILE_WIDTH || document.documentElement.clientWidth <= consts.MOBILE_WIDTH);
    const isTablet = !isMobile && (window.innerWidth <= consts.TABLET_WIDTH || document.documentElement.clientWidth <= consts.TABLET_WIDTH);
    let locale = (window.location && pathName) && pathName.match('^(/en|/de|/fr|/ro|/nl)$|^(/en/|/de/|/fr/|/ro/|/nl/)')
    locale = (locale && locale[0]) ? locale[0].substring(1, 3) : ""
    const languageToSwitchTo = this.shouldSwitchToWhichLang(locale)
    const redirectPath = (languageToSwitchTo === BASE_LANG ? "" : "/" + languageToSwitchTo) + (locale ? curPath.slice(3, curPath.length) : curPath)
    let redirectedLocaleRoute = this.getLocaleRoute(pathName, locale)
    const NO_OF_HOURS = 1
    const pageWithoutNewsleter = curPath && (curPath.includes("/contact") || curPath.includes("/pricing") || curPath.includes("/create-account"))
    return (
      <React.Fragment>
        <Router>
          <div>
            {this.state.showNotification && <MDBNotification show fade iconClassName="icon-heart main-color-font font-weight-bold"
              title={FULL_NAME} message={"Job added to favourites"} bodyClassName="py-lg-4 px-lg-5 px-3" className="green-text font-size-bigger font-weight-bold"
              text="now" autohide={FAVOURITE_JOB_NOTIFICATION_TIME}
              style={{ position: "fixed", top: "10px", right: "10px", zIndex: 9999 }}
            />}
            <NavBarSection locale={locale} changeLang={this.changeLang} chosenLang={this.state.chosenLang} base={base} isMobile={isMobile} isTablet={isTablet} favouriteJobsUrls={this.state.favouriteJobsUrls} />
            <React.Fragment>
              <Switch>
                {languageToSwitchTo && <Redirect to={redirectPath} />}
                {redirectedLocaleRoute && <Redirect to={redirectedLocaleRoute} push />}
                <Route exact path={base} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    isMobile={isMobile}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                    isTablet={isTablet}
                    locale={this.getLocale(match)}
                  />}
                />
                <Route exact path={base + "/blog"} component={BlogPage} />
                <Route exact path={base + "/blog/:postUrl"} render={({ match }) => {
                  return <BlogPostPage postUrl={match.params.postUrl} locale={match.params.locale} />
                }} />
                <Route exact path={[base + routes.de.about, base + routes.en.about, base + routes.fr.about, base + routes.ro.about, base + routes.nl.about]} render={({ match }) => <AboutPage locale={this.getLocale(match)} />} />
                {SITE_NAME === "GDJ" && <Route exact path={[base + routes.de.devtraining, base + routes.en.devtraining, base + routes.fr.devtraining]} render={({ match }) => <PromoPage locale={this.getLocale(match)} />} />}
                {SITE_NAME === "GDJ" && <Route exact path={base + "/honeypot"} render={({ match }) => <HoneyPromoPage locale={this.getLocale(match)} />} />}
                {SITE_NAME === "RDJ" && <Route exact path={base + "/isqi-certification"} render={({ match }) => <IsqiPromoPage locale={this.getLocale(match)} />} />}
                <Route exact path={base + "/react-course"} render={({ match }) => <ReactCoursePage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/contact"} render={({ match }) => <ContactPage locale={this.getLocale(match)} />} />
                {/* <Route exact path={base + "/test-contact"} render={({ match }) => <PrototypeContactPage locale={this.getLocale(match)} />} /> */}
                <Route exact path={base + "/create-account"} render={({ match }) => <ContactPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/open"} render={(props) => <OpenStatsPage />} />
                <Route exact path={base + "/subscribe-newsletter"} render={({ match }) => <NewsletterSubscribePage locale={this.getLocale(match)} />} />
                <Route exact path={[base + routes.de.companies, base + routes.en.companies, base + routes.fr.companies, , base + routes.ro.companies, base + routes.nl.companies]} render={({ match }) => <CompaniesPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/companies/:companyPageUrl"} render={({ match }) => {
                  return <CompanyPage isMobile={isMobile} companyPageUrl={match.params.companyPageUrl} locale={this.getLocale(match)} onFavouriteJobClick={this.onFavouriteJobClick} favouriteJobsUrls={this.state.favouriteJobsUrls} />
                }} />
                <Route exact path={base + "/company-jobs/:liteCompanyPageUrl"} render={({ match }) => {
                  return <LiteCompanyPage isMobile={isMobile} liteCompanyPageUrl={match.params.liteCompanyPageUrl} locale={this.getLocale(match)} onFavouriteJobClick={this.onFavouriteJobClick} favouriteJobsUrls={this.state.favouriteJobsUrls} />
                }} />
                <Route path={[base + routes.de.usefullinks, base + routes.en.usefullinks, base + routes.fr.usefullinks]} render={({ match }) => <LinkListPage jobs={this.state.activeAndSortedJobs} locale={this.getLocale(match)} pageType="none" />} />
                <Route path={base + "/infographics/how-to-find-job-infographic"} render={(props) => <InfographicPage />} />
                {/* <Route path={base + "/menu"} render={({ match }) => <LinkListPage jobs={this.state.activeAndSortedJobs} locale={this.getLocale(match)} pageType="dev" />} /> */}
                <Route path={[base + routes.de.faq, base + routes.en.faq, base + routes.fr.faq, base + routes.ro.faq, base + routes.nl.faq]} render={({ match }) => <FaqPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/privacy-policy"} render={({ match }) => <PrivacyPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/cookie-policy"} render={({ match }) => <CookiePage locale={this.getLocale(match)} onOptOutFromCookies={this.optOutFromCookies} isCookieBannerClosed={this.state.isCookieBannerClosed} onAcceptCookies={this.acceptCookiesAndCloseCookieBanner} />} />
                <Route exact path={base + "/community"} render={({ match }) => <CommunityPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/pricing"} render={({ match }) => <PricingPage pricingVersion="2024" locale={this.getLocale(match)} />} />
                <Route exact path={base + "/pricing-2024"} render={({ match }) => <PricingPage pricingVersion="2024" locale={this.getLocale(match)} />} />
                <Route exact path={base + "/hub/reports/it-job-market-report-2022"} render={({ match }) => <ReportDownloadPage2022 locale={this.getLocale(match)} />} />
                <Route exact path={base + "/hub/reports/it-job-market-report-2023"} render={({ match }) => <ReportDownloadPage2023 locale={this.getLocale(match)} />} />
                <Route exact path={base + "/talent-directory"} render={({ match }) => <TalentLandingPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/my-favourite-jobs"} render={({ match }) => <FavouriteJobsPage locale={this.getLocale(match)} onFavouriteJobClick={this.onFavouriteJobClick} favouriteJobsUrls={this.state.favouriteJobsUrls} />} />
                {SITE_NAME !== "USJ" &&
                  <Route path={base + "/community/:techCat/:cityCat"} render={({ match }) =>
                    <CommunityPage
                      techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                      cityFilterFromUrl={match.params.cityCat}
                      locale={this.getLocale(match)} />}
                  />
                }
                {SITE_NAME === "USJ" &&
                  <Route path={base + "/community/:techCat/:stateCat/:cityCat"} render={({ match }) =>
                    <CommunityPage
                      techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                      cityFilterFromUrl={match.params.cityCat}
                      stateFilterFromUrl={match.params.stateCat}
                      locale={this.getLocale(match)} />}
                  />
                }
                <Route exact path={base + "/resources"} render={({ match }) => <ResourcesPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/tech-job-market-survey"} render={({ match }) => <SurveyInterviewPage isTally={true} locale={this.getLocale(match)} />} />
                <Route exact path={base + "/job-market-survey-test2"} render={({ match }) => <SurveyInterviewPage isTally={true} locale={this.getLocale(match)} />} />
                <Route exact path={base + "/hub"} render={({ match }) => <HubPage locale={this.getLocale(match)} />} />
                <Route exact path={base + "/events"} render={({ match }) => <CalendarPage locale={this.getLocale(match)} />} />
                {SITE_NAME === "USJ" &&
                  <Route path={base + "/jobs/:techCat/:stateCat/:cityCat"} render={({ match }) =>
                    <HomePageWrapperPage
                      techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                      stateFilterFromUrl={match.params.stateCat}
                      cityFilterFromUrl={match.params.cityCat}
                      locale={this.getLocale(match)}
                      onFavouriteJobClick={this.onFavouriteJobClick}
                      favouriteJobsUrls={this.state.favouriteJobsUrls}
                      isListView={true}
                      isMobile={isMobile}
                      isTablet={isTablet}
                    />}
                  />
                }
                <Route exact path={base + "/salaries"} render={({ match }) =>
                  <SalaryStatsPage
                    techFilterFromUrl={ALL}
                    stateFilterFromUrl={ALL}
                    cityFilterFromUrl={ALL}
                    expFilterFromUrl={ALL}
                    isMobile={isMobile}
                    locale={this.getLocale(match)}
                  />}
                />
                {SITE_NAME === "USJ" &&
                  <Route path={base + "/salaries/:techCat/:stateCat/:cityCat/:expLevel"} render={({ match }) =>
                    <SalaryStatsPage
                      techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                      stateFilterFromUrl={match.params.stateCat}
                      cityFilterFromUrl={match.params.cityCat}
                      expFilterFromUrl={match.params.expLevel}
                      locale={this.getLocale(match)}
                      isMobile={isMobile}
                    />}
                  />
                }
                {SITE_NAME !== "USJ" &&
                  <Route path={base + "/jobs/:techCat/:cityCat"} render={({ match }) =>
                    <HomePageWrapperPage
                      techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                      cityFilterFromUrl={match.params.cityCat}
                      onFavouriteJobClick={this.onFavouriteJobClick}
                      favouriteJobsUrls={this.state.favouriteJobsUrls}
                      locale={this.getLocale(match)}
                      isListView={true}
                      isTablet={isTablet}
                      isMobile={isMobile}
                    />}
                  />
                }
                {SITE_NAME !== "USJ" &&
                  <Route path={base + "/salaries/:techCat/:cityCat/:expLevel"} render={({ match }) =>
                    <SalaryStatsPage
                      techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                      cityFilterFromUrl={match.params.cityCat}
                      expFilterFromUrl={match.params.expLevel}
                      locale={this.getLocale(match)}
                      isMobile={isMobile}
                    />}
                  />
                }
                <Route exact path={base + "/for-students"} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    dedicatedFor={"for-students"}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    locale={this.getLocale(match)}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                  />}
                />
                <Route exact path={base + "/entry-level-it-jobs"} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    dedicatedFor={"entry-level-it-jobs"}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    locale={this.getLocale(match)}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                  />}
                />
                <Route exact path={base + "/startup-jobs"} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    dedicatedFor={"startup-jobs"}
                    isTablet={isTablet}
                    isMobile={isMobile}
                    locale={this.getLocale(match)}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                  />}
                />
                <Route exact path={base + "/beta"} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    dedicatedFor={"beta"}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    locale={this.getLocale(match)}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                  />}
                />
                <Route exact path={base + "/ba"} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    dedicatedFor={"ba"}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    locale={this.getLocale(match)}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                  />}
                />
                <Route exact path={base + "/with-visa-sponsorship"} render={({ match }) =>
                  <HomePageWrapperPage
                    isListView={true}
                    dedicatedFor={"with-visa-sponsorship"}
                    isMobile={isMobile}
                    locale={this.getLocale(match)}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls}
                  />}
                />
                {/* <Route path={base + "/jobportals/:techCat"} render={({ match }) =>
                  <JobPortalsPage
                    techFilterFromUrl={match.params.techCat ? this.parseTechCategory(match.params.techCat) : undefined}
                    locale={this.getLocale(match)}
                  />}
                /> */}
                <Route exact path={base + "/jobs/:jobUrl"} render={({ match }) => {
                  return <HomePageWrapperPage
                    jobUrl={match.params.jobUrl}
                    locale={this.getLocale(match)}
                    isListView={false}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    onFavouriteJobClick={this.onFavouriteJobClick}
                    favouriteJobsUrls={this.state.favouriteJobsUrls} />
                }} />
                <Route component={NotFoundPage} />
              </Switch>
            </React.Fragment>
            {(!this.state.isCookieBannerClosed && !this.state.optedOutFromCookies) && !isGoogle && !isPrerender && <CookieBanner locale={locale} onOptOutFromCookies={this.optOutFromCookies} onAcceptCookies={this.acceptCookiesAndCloseCookieBanner} />}
            {(!this.state.isNewsletterBannerClosed && this.state.isPromoBannerSurvey2024Closed && !pageWithoutNewsleter && new Date() - new Date(this.state.timeOfFirstVisit) >= 1000 * 3600 * NO_OF_HOURS) && <NewsletterSubscribeBannerDesktop locale={locale} className="only-desktop" onCloseButtonClick={this.closeNewsletterSubscribe} isMobile={isMobile} />}
            {(!this.state.isPromoBannerSurvey2024Closed && !pageWithoutNewsleter) && this.state.isCookieBannerClosed && <PromoBannerSurvey2024 locale={locale} className="only-desktop" onCloseButtonClick={this.closePromoBannerSurvey2024} isMobile={isMobile} />}
            {/* We don't want the mobile banner because it brings <2% signups and is distracting */}
            {["SDJ", "GDJ", "RDJ", "UKJ"].includes(SITE_NAME) && !pageWithoutNewsleter && (!this.state.isMobileBannerClosed && new Date() - new Date(this.state.timeOfFirstVisit) >= 1000 * 3600 * NO_OF_HOURS) && <NewsletterSubscribeBannerMobile locale={locale} onCloseButtonClick={this.closeMobileBanner} />}
          </div>
        </Router>
      </React.Fragment>
    );
  }
}

const cookieLifetime = () => {
  return new Date(Date.now() + 1000 * 3600 * 24 * 365 * 10)
}

export default App
