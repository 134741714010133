import React from 'react';
import { MDBBtn } from 'mdbreact';
import { Translator } from '../translationService';
import { siteConfig } from '../siteConfig'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const { SITE_NAME } = siteConfig

const translations = {
    de: {
        thankyou: "Danke fürs Abonnieren. Bitte bestätigen Sie Ihren wöchentlichen Newsletter, indem Sie auf den gesendeten Link klicken.",
        getweeklyemail: "Erhalten Sie eine wöchentliche E-Mail mit allen neuen",
        jobs: "Stellenangeboten",
        name: "Ihr Name",
        email: "Ihre E-Mail",
        subscribe: "Abonnieren",
    },
    en: {
        thankyou: "Thank you for subscribing. Please confirm your weekly newsletter by clicking the sent link.",
        getweeklyemail: "Get a weekly email with all new",
        jobs: "jobs",
        name: "Your name",
        email: "Your email",
        subscribe: "Subscribe",
    },
    fr: {
        thankyou: "Merci de vous être abonné. Veuillez confirmer votre newsletter hebdomadaire en cliquant sur le lien envoyé.",
        getweeklyemail: "Recevez un e-mail hebdomadaire avec toutes les nouvelles ",
        jobs: "offres d’emploi",
        name: "Votre nom",
        email: "email",
        subscribe: "Abonnez-vous",
    },
    nl: {
        thankyou: "Bedankt voor het abonneren. Bevestig uw wekelijkse nieuwsbrief door op de verzonden link te klikken.",
        getweeklyemail: "Ontvang wekelijks een e-mail met alle nieuwe ",
        jobs: "vacatures",
        name: "Uw naam",
        email: "Jouw email",
        subscribe: "Abonneren",
    },
    ro: {
        thankyou: "Multumesc pentru abonare. Confirmati newsletterul dvs. saptamanal facand clic pe linkul trimis.",
        getweeklyemail: "Primeste saptamanal lista cu cele mai noi joburi",
        jobs: "joburi",
        name: "Nume",
        email: "E-mail",
        subscribe: "Abonati-va",
    },
}

class NewsletterSubscribeBannerDesktop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false
        };
    }

    handleFormSubmit = e => {
        e.preventDefault();
        // Start: For tracking form submissions in Google Analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'newsletterFormSubmit',
            formId: 'newsletterForm'
        });
        plausible('newsletterFormSubmit', { props: { whereSubscribed: "desktop-banner" } })
        cookies.set("hasSubscribedNewsletter", true, { path: '/', expires: new Date(Date.now() + 1000 * 3600 * 24 * 365 * 10) })
        // End
        const formData = new FormData(e.target);
        formData.set("frequency", "weekly")
        formData.set("whereSubscribed", "desktop-banner")
        fetch('/api/newsletter', {
            method: 'POST',
            body: formData,
        }).then(() => this.setState({ submitted: true, error: false }))
            .catch(() => this.setState({ submitted: true, erorr: true }))
    };

    render() {
        const isMobile = this.props.isMobile //this is legacy we do not show this banner on mobile
        const locale = this.props.locale
        const t = new Translator(locale, translations).t;

        return (
            <React.Fragment>
                {/* {
                    SITE_NAME === "GDJ" &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>
                            <a href="https://jobboersencheck.de/fragebogen?jm=451" target='_blank' className='orange-text font-weight-bold'>Bewerte uns auf JobbörsenCheck.de</a> und gewinne einen 250 EUR Amazon Gutschein!
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                }*/}
                {/* {["SDJ"].includes(SITE_NAME) &&
                    <div className={"newsletter-subscription-panel main-color-background white-text py-3 text-center text-small-mobile " + this.props.className}>
                        <span className='font-size-bigger '>
                            We DON'T message candidates on WhatsApp / SMS. If you received a message from someone claiming to be from SwissDevJobs, <a href="https://www.linkedin.com/posts/swissdev-jobs_we-dont-use-whatsapp-to-message-candidates-activity-7138485757907800064-dQtL" target='_blank' className='orange-text font-weight-bold pointer-cursor'>read this Post.</a>
                        </span>
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5" onClick={() => this.props.onCloseButtonClick()} />
                    </div>
                } */}
                {
                    <div className={"newsletter-subscription-panel main-color-background white-text py-1 text-center text-small-mobile " + this.props.className}>
                        {
                            this.state.submitted ?
                                <div className="d-inline-block my-2 py-1">
                                    {t("thankyou")}
                                </div>
                                :
                                <div className="d-inline-block">
                                    <form onSubmit={this.handleFormSubmit}>
                                        <div className={isMobile ? "" : "d-inline pr-4"}>
                                            {t("getweeklyemail")}
                                            {locale === "fr" ? t("jobs") : ""}
                                            <select className="d-inline form-control mx-2 pr-0 newsletter-subscribe-input-short pl-2" name="techCategory">
                                                <option value="C#">C#</option>
                                                <option value="Cplusplus">C / C++</option>
                                                <option value="DevOps">DevOps</option>
                                                <option value="System">System Engineer</option>
                                                <option value="Java">Java</option>
                                                <option value="JavaScript">JavaScript</option>
                                                <option value="Mobile">Mobile</option>
                                                <option value="PHP">PHP</option>
                                                <option value="Python">Python</option>
                                                <option value="Ruby">Ruby</option>
                                                <option value="UI-UX-Designer">UI / UX</option>
                                                <option value="Data">Data Science / Data Engineering</option>
                                                <option value="Blockchain">Blockchain</option>
                                                <option value="Database">Database</option>
                                                <option value="Golang">Golang</option>
                                                <option value="Tester">QA, Tester</option>
                                                <option value="GameDev">GameDev</option>
                                                <option value="Security">Security</option>
                                                <option value="Machine-Learning">Machine Learning / AI</option>
                                                <option value="SAP">SAP</option>
                                                <option value="Architect">Architect</option>
                                                <option value="Business">Business</option>
                                                <option value="Manager">Manager</option>
                                                <option value="Support">IT Support</option>
                                                <option value="Rust">Rust</option>
                                                <option value="IT">IT / ICT</option>
                                            </select>
                                            {locale === "fr" || SITE_NAME === "RDJ" ? "" : t("jobs")}
                                        </div>
                                        <input className="d-inline form-control pl-2 pr-0 newsletter-subscribe-input-mid ml-2" placeholder={t("name")} name="personName" type="text" required />
                                        <input className="d-inline form-control pl-2 pr-0 newsletter-subscribe-input ml-2" placeholder={t("email")} name="personEmail" type="email" validate="true" error="wrong" success="right" required />
                                        <MDBBtn className="d-inline py-2 ml-3 text-larger text-contrast-accent" color="orange" type="submit">
                                            {t("subscribe")}
                                        </MDBBtn>
                                    </form>
                                </div>
                        }
                        <i className="icon-close float-right big-click-icon pointer-cursor mr-5 pr-5 my-2 pt-1" onClick={() => this.props.onCloseButtonClick()} />
                    </div>}
            </React.Fragment>
        )
    }
}

export default NewsletterSubscribeBannerDesktop;