import React from 'react';
import { MDBBtn } from 'mdbreact';
import { Translator } from '../translationService';
import { withRouter, Link } from 'react-router-dom';

const translations = {
    de: {
        weusecookies: "Wir verwenden Cookies",
        toimproveux: ", um Ihre Benutzererfahrung zu verbessern. Weitere Informationen finden Sie in unseren",
        privacypolicy: "Datenschutzbestimmungen.",
        youcanchange: "Sie können die Einstellungen ändern, indem Sie den Anweisungen in der ",
        cookiepolicy: "Cookie-Richtlinie folgen.",
        managecookies: "Cookies bearbeiten",
        okfine: "Cookies zulassen",
    },
    en: {
        weusecookies: "We use cookies",
        toimproveux: " to make your user experience better, you can read more in our",
        privacypolicy: "Privacy Policy.",
        youcanchange: "You can change the settings by following the instructions in the ",
        cookiepolicy: "Cookie Policy.",
        managecookies: "Manage cookies",
        okfine: "Accept cookies",
    },
    fr: {
        weusecookies: "Nous utilisons des cookies",
        toimproveux: " pour améliorer votre expérience utilisateur, vous pouvez en lire plus dans notre",
        privacypolicy: "politique de confidentialité.",
        youcanchange: "Vous pouvez modifier les paramètres en suivant les instructions de la ",
        cookiepolicy: "politique de cookies.",
        managecookies: "Modifier les cookies",
        okfine: "Accepter les cookies",
    },
    nl: {
        weusecookies: "We gebruiken cookies",
        toimproveux: " om je gebruikerservaring te verbeteren, je kan meer lezen in ons",
        privacypolicy: "Privacybeleid.",
        youcanchange: "Je kan de instellingen wijzigen door de instructies te volgen in het ",
        cookiepolicy: "Cookie Beleid.",
        managecookies: "Cookies beheren",
        okfine: "Accepteer cookies",
    },
    ro: {
        weusecookies: "Utilizam cookie-uri",
        toimproveux: " si tehnologii asemanatoare pentru a-ti imbunatati experienta. Prin continuarea navigarii pe site confirmi acordul tau conform",
        privacypolicy: "Politicii de Confidentialitate.",
        youcanchange: " Poti modifica setarile urmand instructiunile din ",
        cookiepolicy: "Politica de Cookie.",
        managecookies: "Editati cookie",
        okfine: "ACCEPT",
    },
}
class CookieBanner extends React.PureComponent {
    render() {
        const t = new Translator(this.props.locale, translations).t;
        const urlParams = this.props.location ? this.props.location.search : ""
        const utmSource = new URLSearchParams(urlParams).get("utm_source") || ""

        return (
            <div className="cookie-banner main-color-background white-text text-center text-small-mobile py-2 px-sm-5">
                {
                    <React.Fragment>
                        { /* eslint-disable-next-line */}
                        {utmSource.includes("reddit_") && <div className='font-weight-bold pb-2 font-size-bigger-everywhere'>Hello fellow Redditor!</div>}
                        {utmSource.includes("reddit_") && <div><img src="https://static.swissdevjobs.ch/pictures/reddit-icon.webp" className="pb-3" height={70} /></div>}
                        {t("weusecookies")} 🍪{t("toimproveux")}
                        <a className="orange-text font-weight-bold" target="_blank" rel="noopener noreferrer" href="/privacy-policy"> {t("privacypolicy")}</a> {t("youcanchange")}
                        <a className="orange-text font-weight-bold" target="_blank" rel="noopener noreferrer" href="/cookie-policy"> {t("cookiepolicy")}</a>
                        <div className="mt-1">
                            <Link className="orange-text font-weight-bold" to="/cookie-policy">
                                <MDBBtn className="py-2 px-3 ml-4" color="blue" >{t("managecookies")}</MDBBtn>
                            </Link>
                            <MDBBtn className="py-2 px-3 ml-4 text-contrast-accent" color="orange" onClick={this.props.onAcceptCookies}>{t("okfine")}</MDBBtn>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default withRouter(CookieBanner);