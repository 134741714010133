import React from 'react';
import { MDBBtn } from 'mdbreact';
import { Translator } from '../translationService';
import { siteConfig } from '../siteConfig'
const { ANDROID_APP_URL, IOS_APP_URL } = siteConfig

const translations = {
    de: {
        subscribegetjobs: "Erfahren Sie über die neusten Jobs",
    },
    en: {
        subscribegetjobs: "Subscribe to get the newest jobs",
    },
    fr: {
        subscribegetjobs: "Découvrez les derniers emplois",
    },
    nl: {
        subscribegetjobs: "Abonneer je om de nieuwste vacatures te krijgen",
    },
    ro: {
        subscribegetjobs: "Abonati-va pentru a obtine cele mai noi locuri de munca",
    },
}

class NewsletterSubscribeBannerMobile extends React.PureComponent {
    render() {
        const t = new Translator(this.props.locale, translations).t;
        const uaLowCase = navigator.userAgent.toLowerCase();
        const isAndroid = uaLowCase.indexOf("android") > -1;
        const isIOS = /ipad|iphone|ipod/.test(uaLowCase);

        return (
            <React.Fragment>
                {true ?
                    // {SITE_NAME !== "SDJ" ?
                    <MDBBtn className="newsletter-subscription-panel m-0 py-2 px-3 text-larger only-mobile main-color-background">
                        {isAndroid && <React.Fragment><a href={ANDROID_APP_URL + "&referrer=utm_source%3Dsdj_site_banner%26utm_medium%3Dsdj_site_banner%26utm_term%3Dsdj_site_banner%26utm_term%3Dsdj_site_banner"} className="white-text" >
                            Check our Android App (better filters) </a>
                            <i className="icon-close float-right big-click-icon pointer-cursor" onClick={() => this.props.onCloseButtonClick()} />
                        </React.Fragment>}
                        {isIOS && <React.Fragment><a href={IOS_APP_URL + "?pt=126647531&ct=3Dsdj_site_banner&mt=8"} className="white-text" >
                            Check our iOS App (better filters)</a>
                            <i className="icon-close float-right big-click-icon pointer-cursor" onClick={() => this.props.onCloseButtonClick()} />
                        </React.Fragment>}
                    </MDBBtn>
                    :
                    <MDBBtn className="newsletter-subscription-panel m-0 py-2 px-3 text-larger only-mobile main-color-background">
                        We DON'T message candidates on WhatsApp. If you received a message from someone claiming to be from SwissDevJobs, <a href="https://www.linkedin.com/posts/swissdev-jobs_we-dont-use-whatsapp-to-message-candidates-activity-7138485757907800064-dQtL" target='_blank' className='orange-text font-weight-bold pointer-cursor'>read this Post.</a>
                        <i className="icon-close float-right big-click-icon pointer-cursor" onClick={() => this.props.onCloseButtonClick()} />
                    </MDBBtn>
                }
            </React.Fragment>
            // <MDBBtn className="newsletter-subscription-panel m-0 py-2 px-3 text-larger only-mobile main-color-background">
            //     Want to win some SwissDevJobs gadgets? 
            //     <a href="https://www.linkedin.com/posts/swissdev-jobs_have-you-ever-wondered-how-to-get-some-cool-activity-7110178273980010496-2H1z/" className="orange-text font-weight-bold" >
            //     Check this LinkedIn Post
            //         <i className="icon-close float-right big-click-icon pointer-cursor" onClick={() => this.props.onCloseButtonClick()} />
            //     </a></MDBBtn>

        )
    }
}

export default NewsletterSubscribeBannerMobile;