import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import './css/job-custom.css';
import './css/main-custom.css';
import './css/techcategory-custom.css'
import './css/icomoon.css'

import { siteConfig } from './siteConfig'

import App from './App'
const { FULL_NAME, STICKER_SRC } = siteConfig

const isIE = /*@cc_on!@*/false || !!document.documentMode;

const container = document.getElementById('root');
const root = createRoot(container);

if (isIE) {
    root.render(
        <span className="main-color-font text-center">
            <img src={STICKER_SRC} className="img-fluid centered-small-image mt-3" alt={FULL_NAME + " round logo sticker"}></img>
            <h3 className="m-5">We are sorry, this website is not supported on <b>Internet Explorer</b>. <div className="mt-3">(Microsoft does not support <b>Internet Explorer</b> anymore)</div></h3>
            <h3 className="m-5">Please use a modern browser like: <b>Firefox, Edge, Safari or Chrome.</b></h3>
        </span>);
} else {
    root.render(<App />);
}