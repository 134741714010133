import { siteConfig } from './siteConfig'
import SDJUtil from 'swissdev-url-generator'
import Cookies from 'universal-cookie';
import { rootWithLocale, Translator, commonTranslations } from './translationService';

const { SITE_NAME, BASE_LANG, STATIC_ROOT, CITY_CATEGORIES, STATE_CATEGORIES, TECH_CATEGORIES, ADDITIONAL_TECH_CATEGORIES, SUPPORTED_LANGS, COUNTRY_CODE } = siteConfig
export const ALL = "all"
const IMG_ROOT = STATIC_ROOT + "/logo-images/"

export const onSocialButtonClick = socialName => {
    plausible('socialButtonClick', { props: { social: socialName } })
}

export const generateCanonicalForJob = detailedJob => {
    return rootWithLocale(SDJUtil.getLocaleFromLanguage(detailedJob.language, BASE_LANG, SUPPORTED_LANGS)) + "/jobs/" + detailedJob.jobUrl
}

export const detectIsSafari = () => {
    const isIos = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    const isDesktopSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    return isIos || isDesktopSafari
}

export const getCanonicalUrlForSalary = ({ techCategory, cityCategory, stateCategory, expLevel = ALL }) => {
    const capitalizedCityCategory = getCapitalizedCategory(cityCategory, "-")
    const escapedTechCategory = techCategory.replace(/ /g, "-")
    if (techCategory === ALL && cityCategory === ALL && (!stateCategory || stateCategory === ALL)) {
        return "/salaries"
    }
    if (SITE_NAME === "USJ") {
        const capitalizedStateCategory = getCapitalizedCategory(stateCategory, "-")
        return "/salaries/" + escapedTechCategory + "/" + capitalizedStateCategory + "/" + capitalizedCityCategory + "/" + expLevel
    }
    return "/salaries/" + escapedTechCategory + "/" + cityCategory + "/" + expLevel
}

export const safelyParseJSON = ({ jsonObj, contextInfo }) => {
    let parsed = {}
    try {
        parsed = JSON.parse(jsonObj)
    } catch (e) {
        const errorMsg = "ERROR: Client problem with JSON parsing: " + contextInfo + " object: " + jsonObj
        console.log(errorMsg)
        fetch('/api/errors/report', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ errorMessage: errorMsg, url: window.location.href, errorType: "JSON_RESPONSE_PARSING" })
        })
    }

    return parsed
}

// export const safelyParseResponseJSON = async ({ responz, contextInfo }) => {
//     let parsed = {}
//     try {
//         parsed = await responz.json();
//     } catch (e) {
//         const errorMsg = "ERROR: Client problem with JSON parsing: " + contextInfo
//         console.log(errorMsg)
//         fetch('/api/errors/report', {
//             method: 'POST',
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify({ errorMessage: errorMsg, url: window.location.href, errorType: "JSON_RESPONSE_PARSING" })
//         })
//     }

//     return parsed
// }

export const addToFavouritesAndReturnNew = (job, currentFavourites = [], triggerNotification) => {
    const cookies = new Cookies()
    let newFavourites = currentFavourites
    const jobSlug = SDJUtil.generateUrlForJob(job, SITE_NAME)
    if (currentFavourites.includes(jobSlug)) {
        newFavourites = newFavourites.filter(jobUrl => jobUrl !== jobSlug)
        plausible('removedFromFavourites', { props: { jobUrl: jobSlug } })
    } else {
        newFavourites.push(jobSlug)
        triggerNotification()
        plausible('addedToFavourites', { props: { jobUrl: jobSlug } })
    }
    cookies.set("favouriteJobs", JSON.stringify(newFavourites), { path: '/', expires: new Date(Date.now() + 1000 * 3600 * 24 * 365 * 10) })

    return newFavourites
}

export const getDisplayedTechCategory = category => {
    let displayedCategory = category
    if (category) {
        if (category.toUpperCase() === "DOTNET") {
            displayedCategory = "C#.NET"
        }
        if (category.toUpperCase() === "C++") {
            displayedCategory = "C / C++"
        }
        if (category.toUpperCase() === "DATA-SCIENCE") {
            displayedCategory = "Data Science"
        }
        if (category.toUpperCase() === "UI-UX-DESIGNER") {
            displayedCategory = "UX, UI"
        }
        // if (category.toUpperCase() === "SAP") {
        //     displayedCategory = "SAP, BI"
        // }
        if (category.toUpperCase() === "TESTER") {
            displayedCategory = "QA, Test"
        }
        if (category.toUpperCase() === "MACHINE-LEARNING") {
            displayedCategory = "ML, AI"
        }
        if (category.toUpperCase() === "ALL") {
            displayedCategory = "Software"
        }
    }
    return displayedCategory
}

export const isTechWithLink = tech => {
    return ADDITIONAL_TECH_CATEGORIES.includes(tech) || TECH_CATEGORIES.includes(tech)
}

export const getDisplaySeoTitleCategory = (techCategory, locale) => {
    if (!locale) {
        locale = BASE_LANG
    }
    if (locale === "ro") {
        if (!techCategory || techCategory === ALL) return "IT & Software Developer"
        if (techCategory === "Tester") return "QA Engineer / Software Tester"
        if (techCategory === "IT") return "IT"
        if (techCategory === "Security") return "Securitate Cibernetica"
        if (techCategory === "Manager") return "Manager IT"
        if (techCategory === "Data") return "Data Analyst"
        if (techCategory === "Python") return "Python Programator"
        if (techCategory === "Product Owner") return "Product Owner"
        if (techCategory === "Product Manager") return "Product Manager"
        if (techCategory === "SAP") return "SAP Consultant / Developer"
        if (techCategory === "SQL") return "SQL Developer"
        if (techCategory === "Business Intelligence") return "BI Developer / Analyst"
        if (techCategory === "dotNET") return ".NET Developer"
        if (techCategory === "C++") return "Programator C++"
        if (techCategory === "Oracle") return "Oracle"
        if (techCategory === "Architect") return "Arhitect IT"
        if (techCategory === "PHP") return "PHP Programator"
        if (techCategory === "Embedded") return "Embedded Software Developer"
        if (techCategory === "Cloud") return "Cloud Engineer"
        if (techCategory === "Business") return "Business Analyst"
        if (techCategory === "Mobile") return "Mobile Developer"
    }
    if (locale === "de") {
        if (techCategory === ALL) return "Softwareentwickler"
        if (techCategory === "C++") return "C / C++ Softwareentwickler"
        if (techCategory === "dotNET") return "C# Softwareentwickler"
        if (techCategory === "Data-Science") return "Data Science & Engineer"
        if (techCategory === "Data") return "Data Science & Engineer"
        if (techCategory === "Java") return "Java Entwickler"
        if (techCategory === "JavaScript") return "JavaScript Entwickler"
        if (techCategory === "Mobile") return "App (iOS, Android) Entwickler"
        if (techCategory === "PHP") return "PHP Entwickler"
        if (techCategory === "Python") return "Python Entwickler"
        if (techCategory === "Ruby") return "Ruby on Rails Entwickler"
        if (techCategory === "Scala") return "Scala Entwickler"
        if (techCategory === "Business") return "IT Projektmanager"
        if (techCategory === "UI-UX-Designer") return "UX Designer"
        if (techCategory === "IT") return "Informatiker, ICT, Applikationsentwickler"
        if (techCategory === "DevOps") return "DevOps Ingenieur"
        if (techCategory === "System") return "Systemingenieur"
        if (techCategory === "Tester") return "QA, Software Tester"
        if (techCategory === "AWS") return "AWS Cloud Ingenieur"
        if (techCategory === "Business Intelligence") return "Business Intelligence"
        if (techCategory === "Cisco") return "Cisco"
        if (techCategory === "Citrix") return "Citrix"
        if (techCategory === "Computer Vision") return "Computer Vision Ingenieur"
        if (techCategory === "CRM") return "CRM Ingenieur"
        if (techCategory === "D3") return "D3 JS Entwickler"
        if (techCategory === "Embedded") return "Embedded Softwareentwickler"
        if (techCategory === "ExpressJS") return "Express JS Entwickler"
        if (techCategory === "Figma") return "Figma"
        if (techCategory === "Git") return "Git"
        if (techCategory === "IAM") return "IAM Ingenieur"
        if (techCategory === "GIS") return "GIS"
        if (techCategory === "IBM") return "IBM Software Ingenieur"
        if (techCategory === "ICT") return "ICT"
        if (techCategory === "Kubernetes") return "Kubernetes"
        if (techCategory === "Microsoft Dynamics 365 CRM") return "Microsoft Dynamics Entwickler"
        if (techCategory === "Puppet") return "Puppet Ingenieur"
        if (techCategory === "Rails") return "Ruby on Rails Entwickler"
        if (techCategory === "Swing") return "Java Swing Entwickler"
        if (techCategory === "Unreal") return "Unreal Engine"
        if (techCategory === "Veeam") return "Veeam"
        if (techCategory === "Zend") return "Zend"

    } else if (locale === "fr") {
        if (techCategory === ALL) return "Développeur"
        if (techCategory === "C++") return "Développeur C++"
        if (techCategory === "dotNET") return "Développeur C#"
        if (techCategory === "Data-Science") return "Data Science & Ingénieur"
        if (techCategory === "Data") return "Data Science & Ingénieur"
        if (techCategory === "Java") return "Développeur Java"
        if (techCategory === "JavaScript") return "Développeur JavaScript"
        if (techCategory === "Mobile") return "Développeur Android / iOS"
        if (techCategory === "PHP") return "Développeur PHP"
        if (techCategory === "Python") return "Développeur Python"
        if (techCategory === "Ruby") return "Développeur Ruby on Rails"
        if (techCategory === "Scala") return "Développeur Scala"
        if (techCategory === "Business") return "Gestionnaire de projet"
        if (techCategory === "UI-UX-Designer") return "UI / UX designer"
        if (techCategory === "IT") return "Jobs TIC"
        if (techCategory === "Tester") return "QA, Test"
        if (techCategory === "DevOps") return "Ingénieur DevOps"
        if (techCategory === "System") return "Ingénieur System"
    } else if (locale === "nl") {
        if (techCategory === ALL) return "Software Ontwikkelaar"
        if (techCategory === "C++") return "C / C++ Ontwikkelaar"
        if (techCategory === "dotNET") return ".NET Ontwikkelaar"
        if (techCategory === "Data-Science") return "Data Science & Engineering"
        if (techCategory === "Data") return "Data Science & Engineering"
        if (techCategory === "Java") return "Java Ontwikkelaar"
        if (techCategory === "JavaScript") return "JavaScript Ontwikkelaar"
        if (techCategory === "Mobile") return "Mobile app (iOS, Android) Ontwikkelaar"
        if (techCategory === "PHP") return "PHP Ontwikkelaar"
        if (techCategory === "Python") return "Python Ontwikkelaar"
        if (techCategory === "Ruby") return "Ruby on Rails Ontwikkelaar"
        if (techCategory === "Scala") return "Scala Ontwikkelaar"
        if (techCategory === "Business") return "Product / Project Manager"
        if (techCategory === "UI-UX-Designer") return "UI / UX Ontwerper"
        if (techCategory === "IT") return "IT & ICT"
        if (techCategory === "Tester") return "QA, Software Tester"
        if (techCategory === "Support") return "IT Support"
        if (techCategory === "DevOps") return "DevOps Ingenieur"
        if (techCategory === "System") return "System Ingenieur"
        if (techCategory === "Manager") return "IT Management"
        if (techCategory === "Network") return "Network Ingenieur"
    } else {
        if (techCategory === ALL) return "Software Developer"
        if (techCategory === "C++") return "C / C++ Developer"
        if (techCategory === "dotNET") return ".NET Developer"
        if (techCategory === "Data-Science") return "Data Science & Engineering"
        if (techCategory === "Data") return "Data Science & Engineering"
        if (techCategory === "Java") return "Java Developer"
        if (techCategory === "JavaScript") return "JavaScript Developer"
        if (techCategory === "Mobile") return "Mobile app (iOS, Android) Developer"
        if (techCategory === "PHP") return "PHP Developer"
        if (techCategory === "Python") return "Python Developer"
        if (techCategory === "Ruby") return "Ruby on Rails Developer"
        if (techCategory === "Scala") return "Scala Developer"
        if (techCategory === "Business") return "Product / Project Manager"
        if (techCategory === "UI-UX-Designer") return "UI / UX Designer"
        if (techCategory === "IT") return "IT & ICT"
        if (techCategory === "Tester") return "QA, Software Tester"
        if (techCategory === "Support") return "IT Support"
        if (techCategory === "DevOps") return "DevOps Engineer"
        if (techCategory === "System") return "System Engineer"
        if (techCategory === "Manager") return "IT Management"
        if (techCategory === "Network") return "Network Engineer"
        if (techCategory === "AWS") return "AWS Cloud Engineer"
        if (techCategory === "Business Intelligence") return "Business Intelligence"
        if (techCategory === "Cisco") return "Cisco"
        if (techCategory === "Citrix") return "Citrix"
        if (techCategory === "Computer Vision") return "Computer Vision Engineer"
        if (techCategory === "CRM") return "CRM Engineer"
        if (techCategory === "D3") return "D3 JS Developer"
        if (techCategory === "Embedded") return "Embedded Software Engineer"
        if (techCategory === "ExpressJS") return "Express JS Developer"
        if (techCategory === "Figma") return "Figma Designer"
        if (techCategory === "Git") return "GitHub Developer"
        if (techCategory === "IAM") return "IAM Engineer"
        if (techCategory === "GCP") return "GCP Engineer"
        if (techCategory === "GIS") return "GIS"
        if (techCategory === "IBM") return "IBM Software Engineer"
        if (techCategory === "ICT") return "ICT Engineer"
        if (techCategory === "Kubernetes") return "Kubernetes Engineer"
        if (techCategory === "macOS") return "MacOS Developer"
        if (techCategory === "Microsoft Dynamics 365 CRM") return "Microsoft Dynamics Developer"
        if (techCategory === "Puppet") return "Puppet Engineer"
        if (techCategory === "Rails") return "Ruby on Rails Developer"
        if (techCategory === "Swing") return "Java Swing Developer"
        if (techCategory === "Unreal") return "Unreal Engine"
        if (techCategory === "Veeam") return "Veeam Software"
        if (techCategory === "Zend") return "Zend Framework"
        if (techCategory === "IoT") return "IoT Engineer"
        if (techCategory === "Linux") return "Linux Administrator"

    }

    const t = new Translator(locale, [commonTranslations]).t
    console.log("I AM HERE ")
    return techCategory + " " + t(getDeveloperLabel(techCategory, "single"))
}

export const getDisplayedLocationUsa = (activeFilters) => {
    if (activeFilters.cityCategory !== "all") {
        return getCapitalizedCategory(activeFilters.cityCategory)
    } else {
        if (activeFilters.stateCategory !== "all") {
            return getCapitalizedCategory(activeFilters.stateCategory)
        } else {
            return "United States"
        }
    }
}

export const getDeveloperLabel = (tech, singleOrPlural) => {
    if (tech === "UI-UX-Designer") {
        return singleOrPlural === "single" ? "designer" : "designers"
    }
    if (["Business", "Manager", "Architect", "IT"].includes(tech) || (SITE_NAME === "RDJ" && tech === "Tester")) {
        return singleOrPlural === "single" ? "specialist" : "specialists"
    }
    if (["DevOps", "System", "IT", "Data", "Tester", "Support", "Security", "AWS", "GCP", "Cloud", "Azure"].includes(tech)) {
        return singleOrPlural === "single" ? "engineer" : "engineers"
    }

    return singleOrPlural === "single" ? "developer" : "developers"
}

export const getEngineerLabel = (tech, singleOrPlural) => {
    if (tech === "UI-UX-Designer") {
        return singleOrPlural === "single" ? "designer" : "designers"
    }
    if (["Business", "Manager", "Architect", "IT"].includes(tech) || (SITE_NAME === "RDJ" && tech === "Tester")) {
        return singleOrPlural === "single" ? "specialist" : "specialists"
    }

    return singleOrPlural === "single" ? "engineer" : "engineers"
}

export const getDisplayedCity = (cityCategory, locale) => {
    if (SITE_NAME === "USJ") {
        return getCapitalizedCategory(cityCategory)
    }
    let displayedCategory = cityCategory
    if (cityCategory === "remote") displayedCategory = "remote"
    if (cityCategory === "St-Gallen") displayedCategory = "St. Gallen"
    if (cityCategory === "St-Gallen" && locale === "fr") displayedCategory = "Saint Gall"
    if (cityCategory === "Geneva" && locale === "de") displayedCategory = "Genf"
    if (cityCategory === "Fribourg" && locale === "de") displayedCategory = "Freiburg"
    if (cityCategory === "Sion" && locale === "de") displayedCategory = "Sitten"
    if (cityCategory === "Chur" && locale === "fr") displayedCategory = "Coire"
    if (cityCategory === "Geneva" && locale === "fr") displayedCategory = "Genève"
    if (cityCategory === "Basel" && locale === "fr") displayedCategory = "Bâle"
    if (cityCategory === "Bern" && locale === "fr") displayedCategory = "Berne"
    if (cityCategory === "Biel" && locale === "fr") displayedCategory = "Bienne"
    if (cityCategory === "Luzern" && locale === "fr") displayedCategory = "Lucerne"
    if (cityCategory === "Schaffhausen" && locale === "fr") displayedCategory = "Schaffhouse"
    if (cityCategory === "Winterthur" && locale === "fr") displayedCategory = "Winterthour"
    if (cityCategory === "Thun" && locale === "fr") displayedCategory = "Thoune"
    if (cityCategory === "Zug" && locale === "fr") displayedCategory = "Zoug"
    if (cityCategory === "Dusseldorf") displayedCategory = "Düsseldorf"
    if (cityCategory === "Munich" && locale === "") displayedCategory = "München" //because on GTJ base lang is DE
    if (cityCategory === "Muenster") displayedCategory = "Münster"
    if (cityCategory === "Wuerzburg") displayedCategory = "Würzburg"
    if (cityCategory === "Saarbrucken") displayedCategory = "Saarbrücken"
    if (cityCategory === "Nuremberg" && locale === "") displayedCategory = "Nürnberg"
    if (cityCategory === "Cologne" && locale === "") displayedCategory = "Köln"
    if (cityCategory === "Hanover" && locale === "") displayedCategory = "Hannover"
    if (cityCategory === "Zurich" && locale === "de") displayedCategory = "Zürich"

    if (cityCategory === "Den-Haag" && locale === "") displayedCategory = "Den Haag"
    if (cityCategory === "Den-Haag" && locale === "en") displayedCategory = "The Hague"
    if (cityCategory === "s-Hertogenbosch") displayedCategory = "'s-Hertogenbosch"

    return displayedCategory
}

export const getCapitalizedCategory = (locationCategory = "", joinSign = " ") => {
    if (locationCategory === ALL) return ALL
    if (locationCategory === "remote") return "remote"
    if (locationCategory.toLowerCase() === "district-of-columbia") return "D.C."
    const locationArray = locationCategory.split("-")
    return locationArray.map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(joinSign)
}

export const getFilterLink = (cityCategory, techCategory, locale, stateCategory) => {
    const linkTechCategory = TECH_CATEGORIES.includes(techCategory) ? techCategory : ALL

    if (SITE_NAME === "HNM" && stateCategory && cityCategory) {
        return "/jobs/" + stateCategory + "/" + cityCategory
    }
    if (SITE_NAME === "USJ") {
        return "/jobs/" + linkTechCategory + "/" + stateCategory + "/" + cityCategory

    }
    let localePrefix = "/jobs/"
    if (locale === "en") localePrefix = "/en/jobs/"
    if (locale === "de") localePrefix = "/de/jobs/"
    if (locale === "fr") localePrefix = "/fr/jobs/"
    const link = localePrefix + linkTechCategory + "/" + cityCategory

    return link
}

export const getUsaFilterLink = (categories) => {
    const linkTechCategory = TECH_CATEGORIES.includes(categories.techCategory) ? categories.techCategory : ALL
    return "/jobs/" + linkTechCategory + "/" + categories.stateCategory + "/" + categories.cityCategory
}

export const createCategoryMetaTitleHNM = (stateCategory, cityCategory, jobNumber) => {
    if (stateCategory && stateCategory.toLowerCase() !== ALL && cityCategory && cityCategory.toLowerCase() !== ALL) {
        return jobNumber + " active job openings in " + getCapitalizedCategory(cityCategory) + ", " + mapStateCode(stateCategory) + " - HiringNearMe"
    } else if (stateCategory && stateCategory.toLowerCase() !== ALL) {
        return jobNumber + " active job openings in " + getCapitalizedCategory(stateCategory) + " - HiringNearMe"
    } else {
        return jobNumber + " active job openings in United States - HiringNearMe"
    }
}

export const createJobMetaTitleHNM = (job) => {
    if (!job || !job.name) return "Jobs in United States"
    return job.name + ", job in " + getCapitalizedCategory(job.cityCategory) + ", " + mapStateCode(job.stateCategory) + " - HiringNearMe"
}

export const createJobMetaTitle = ({ job, actualCity, locale }) => {
    if (SDJUtil.isRemote(job)) {
        return "Remote " + job.name + " | " + job.company
    } else {
        return job.name + " Job in " + getDisplayedCity(actualCity, locale) + " | " + job.company
    }
}

export const getDisplayedAddressHNM = job => {
    const addressArray = [job.address, job.postalCode, getCapitalizedCategory(job.cityCategory), mapStateCode(job.stateCategory)]
    return addressArray.filter(elem => elem).join(", ")
}

export const getDisplayedAddress = (job, locale) => {
    const addressArray = [job.address, getDisplayedCity(job.actualCity, locale) || (SITE_NAME === "UKJ" && job.cityCategory)]
    return addressArray.filter(elem => elem).join(", ")
}

export const getLogoUrl = ({ logoImg, isPartner }) => {
    return logoImg ? (isPartner ? logoImg : IMG_ROOT + logoImg) : (IMG_ROOT + "placeholder-logo.png")
}

export const getRemotePart = job => {
    let remotePart = "+ Remote"
    if (job.remoteType === "onlycountry") remotePart += " (" + COUNTRY_CODE + ")"
    if (job.remoteType === "countryandeu") remotePart += " (EU)"

    return remotePart
}

export const createJobMetaDescriptionHNM = (job) => {
    if (!job || !job.name) return "Check over 100'000 jobs in United States and find the best jobs near you!"
    return job.company + " is looking for " + job.name + " right now in " + getCapitalizedCategory(job.cityCategory) + ", " + mapStateCode(job.stateCategory) + ". Check if this job meets your needs or look for similar ones!"
}

export const createCategoryMetaDescriptionHNM = (stateCategory, cityCategory, jobNumber) => {
    if (stateCategory && stateCategory.toLowerCase() !== ALL && cityCategory && cityCategory.toLowerCase() !== ALL) {
        return "Check " + jobNumber + " active job openings hiring in " + getCapitalizedCategory(cityCategory) + ", " + mapStateCode(stateCategory) + " right now. Full-time &amp; part-time jobs opportunities. Competitive salaries."
    } else if (stateCategory && stateCategory.toLowerCase() !== ALL) {
        return "Check " + jobNumber + " active job openings hiring in " + getCapitalizedCategory(stateCategory) + " right now. Full-time &amp; part-time jobs opportunities. Competitive salaries."
    } else {
        return "Check " + jobNumber + " active job openings hiring in the United States right now. Full-time &amp; part-time jobs opportunities. Competitive salaries."
    }
}

export const deductStateBasedOnCity = cityCategory => {
    return STATE_CATEGORIES.find(state => CITY_CATEGORIES[state].includes(cityCategory))
}

export const mapStateCode = stateCategory => {
    const displayedState = getCapitalizedCategory(stateCategory)
    const stateCode = stateCodes.find(state => state.name === displayedState)
    return stateCode && stateCode.code || "TX"
}

export const formatCash = (salary, siteName) => {
    if (!salary) return 0
    salary = new Number(salary)
    if (siteName === "GDJ") {
        return salary.toLocaleString("de-DE") + " EUR"
    } else if (siteName === "RDJ") {
        return salary.toLocaleString("ro-RO") + " RON"
    } else if (siteName === "UKJ") {
        return salary.toLocaleString("en-GB") + " GBP"
    } else if (siteName === "USJ") {
        return salary.toLocaleString("en-US") + " USD"
    } else if (siteName === "NDJ") {
        return salary.toLocaleString("nl-NL") + " EUR"
    } else {
        return salary.toLocaleString("de-CH") + " CHF"
    }
}

export const aOrAnArticle = phrase => {

    // Getting the first word 
    var match = /\w+/.exec(phrase);
    if (match)
        var word = match[0];
    else
        return "an";

    var l_word = word.toLowerCase();
    // Specific start of words that should be preceeded by 'an'
    var alt_cases = ["honest", "hour", "hono"];
    for (var i in alt_cases) {
        if (l_word.indexOf(alt_cases[i]) == 0)
            return "an";
    }

    // Single letter word which should be preceeded by 'an'
    if (l_word.length == 1) {
        if ("aedhilmnorsx".indexOf(l_word) >= 0)
            return "an";
        else
            return "a";
    }

    // Capital words which should likely be preceeded by 'an'
    if (word.match(/(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/)) {
        return "an";
    }

    // Special cases where a word that begins with a vowel should be preceeded by 'a'
    const regexes = [/^e[uw]/, /^onc?e\b/, /^uni([^nmd]|mo)/, /^u[bcfhjkqrst][aeiou]/]
    for (var i in regexes) {
        if (l_word.match(regexes[i]))
            return "a"
    }

    // Special capital words (UK, UN)
    if (word.match(/^U[NK][AIEO]/)) {
        return "a";
    }
    else if (word == word.toUpperCase()) {
        if ("aedhilmnorsx".indexOf(l_word[0]) >= 0)
            return "an";
        else
            return "a";
    }

    // Basic method of words that begin with a vowel being preceeded by 'an'
    if ("aeiou".indexOf(l_word[0]) >= 0)
        return "an";

    // Instances where y follwed by specific letters is preceeded by 'an'
    if (l_word.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/))
        return "an";

    return "a";
}

const stateCodes = [
    {
        name: "Alabama",
        code: "AL"
    },
    {
        name: "Alaska",
        code: "AK"
    },
    {
        name: "American Samoa",
        code: "AS"
    },
    {
        name: "Arizona",
        code: "AZ"
    },
    {
        name: "Arkansas",
        code: "AR"
    },
    {
        name: "California",
        code: "CA"
    },
    {
        name: "Colorado",
        code: "CO"
    },
    {
        name: "Connecticut",
        code: "CT"
    },
    {
        name: "Delaware",
        code: "DE"
    },
    {
        name: "District Of Columbia",
        code: "DC"
    },
    {
        name: "D.C.",
        code: "DC"
    },
    {
        name: "Federated States Of Micronesia",
        code: "FM"
    },
    {
        name: "Florida",
        code: "FL"
    },
    {
        name: "Georgia",
        code: "GA"
    },
    {
        name: "Guam",
        code: "GU"
    },
    {
        name: "Hawaii",
        code: "HI"
    },
    {
        name: "Idaho",
        code: "ID"
    },
    {
        name: "Illinois",
        code: "IL"
    },
    {
        name: "Indiana",
        code: "IN"
    },
    {
        name: "Iowa",
        code: "IA"
    },
    {
        name: "Kansas",
        code: "KS"
    },
    {
        name: "Kentucky",
        code: "KY"
    },
    {
        name: "Louisiana",
        code: "LA"
    },
    {
        name: "Maine",
        code: "ME"
    },
    {
        name: "Marshall Islands",
        code: "MH"
    },
    {
        name: "Maryland",
        code: "MD"
    },
    {
        name: "Massachusetts",
        code: "MA"
    },
    {
        name: "Michigan",
        code: "MI"
    },
    {
        name: "Minnesota",
        code: "MN"
    },
    {
        name: "Mississippi",
        code: "MS"
    },
    {
        name: "Missouri",
        code: "MO"
    },
    {
        name: "Montana",
        code: "MT"
    },
    {
        name: "Nebraska",
        code: "NE"
    },
    {
        name: "Nevada",
        code: "NV"
    },
    {
        name: "New Hampshire",
        code: "NH"
    },
    {
        name: "New Jersey",
        code: "NJ"
    },
    {
        name: "New Mexico",
        code: "NM"
    },
    {
        name: "New York",
        code: "NY"
    },
    {
        name: "North Carolina",
        code: "NC"
    },
    {
        name: "North Dakota",
        code: "ND"
    },
    {
        name: "Northern Mariana Islands",
        code: "MP"
    },
    {
        name: "Ohio",
        code: "OH"
    },
    {
        name: "Oklahoma",
        code: "OK"
    },
    {
        name: "Oregon",
        code: "OR"
    },
    {
        name: "Palau",
        code: "PW"
    },
    {
        name: "Pennsylvania",
        code: "PA"
    },
    {
        name: "Puerto Rico",
        code: "PR"
    },
    {
        name: "Rhode Island",
        code: "RI"
    },
    {
        name: "South Carolina",
        code: "SC"
    },
    {
        name: "South Dakota",
        code: "SD"
    },
    {
        name: "Tennessee",
        code: "TN"
    },
    {
        name: "Texas",
        code: "TX"
    },
    {
        name: "Utah",
        code: "UT"
    },
    {
        name: "Vermont",
        code: "VT"
    },
    {
        name: "Virgin Islands",
        code: "VI"
    },
    {
        name: "Virginia",
        code: "VA"
    },
    {
        name: "Washington",
        code: "WA"
    },
    {
        name: "West Virginia",
        code: "WV"
    },
    {
        name: "Wisconsin",
        code: "WI"
    },
    {
        name: "Wyoming",
        code: "WY"
    }
]