import React from "react";

const LoadingComponent = ({ error, pastDelay, timedOut }) => {
  if (error) {
    reportError("Unkown error when loading: " + error)
    return <div className="mt-5 pt-5 mx-4 text-center h2-responsive">Sorry, there was a problem - please refresh the page</div>;
  } else if (timedOut) {
    reportError("Timeout error when loading")
    return <div className="mt-5 pt-5 mx-4 text-center h2-responsive">Sorry, there was a problem - please refresh the page</div>;
  } else if (pastDelay) {
    return <div className="main-color-font pt-2"><div className="d-flex justify-content-center"><div className="py-1 my-5 main-color-font spinner-border fast"></div></div><div className="text-center font-size-bigger">Just a second 😊</div></div>;
  } else {
    return null;
  }
};

const reportError = message => {
  fetch('/api/errors/report', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ errorMessage: message, url: window.location.href, errorType: "COMPONENT_LOADING" })
  })
}

export default LoadingComponent