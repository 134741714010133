import React from 'react';
import { MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-germantechjobs";

class NotFoundPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true
        };
        this.timerHandle = setTimeout(() => this.setState({ showLoader: false }), 2000)
    }

    componentDidMount = () => {
        plausible("404", { props: { path: document.location.pathname } })
    }

    componentWillUnmount = () => {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <Helmet placement="top">
                    <title>{this.state.showLoader ? "Loading" : "404 saddly, a page not found"}</title>
                    <meta name="prerender-status-code" content="410" />
                    <meta name="robots" content="index, follow" />
                </Helmet>
                <MDBRow>
                    <MDBCol className="d-flex justify-content-center text-center">
                        {this.state.showLoader ?
                            <div>
                                <div className="spinner-border fast mt-5 text-primary" role="status"></div>
                            </div>
                            :
                            <div>
                                <h1 className="my-5">404 <span className="far fa-frown" /></h1>
                                <h4 className="my-5">Sorry, it seems that this page doesn't exist anymore [sad-frog.jpg]</h4>
                                <NavLink to="/">
                                    <MDBBtn className="text-larger main-color-background">Let's go home</MDBBtn>
                                </NavLink>
                            </div>
                        }
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}

export default NotFoundPage;
